const routes = {
  "/login": "Login",
  "/": "Dashboard",
  "/dashboard": "Dashboard",
  "/users-overview": "Users Overview",
  "/user-overview": "User Overview",
  "/agile-user-overview/:id": "Agile-User Overview",
  "/classrooms": "Classrooms Overview",
  "/insructors": "Instructors Overview",
  "/teams": "Teams Overview",
  "/adminUsers": "Admin Users Overview",
  "/reports": "Reports",
};

export default routes;
