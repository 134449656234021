import React, { Component } from "react";
import { Button, Alert, Badge } from "reactstrap";
import styled from "styled-components";
import Papa from "papaparse";
import { TableHeaderColumn } from "react-bootstrap-table";
import StyledBootstrapTable from "../../../../components/styled/BootstrapTable.js";
import {AgilePost} from "../../../../services/ApiService.js"

const UploadHeader = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	> .btn:first-child {
		position: relative;
		overflow: hidden;
		display: inline-block;
	}

	> .add-users-btn {
		margin-left: auto;
	}

	> * {
		margin: 0 10px 0;
		padding: 0.5rem 0.75rem;
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
`;

class ImportAgileUsers extends Component {
	state = {
		uploadData: [],
		feedback: ""
	};

	handleFileUpload = () => {
		const that = this;
		Papa.parse(this.uploadFileRef.files[0], {
			header: true,
			dynamicTyping: true,
			complete(results) {
				const feedback = {
					text: this.errors
						? this.errors[0].message
						: "File successfully parsed.",
					color: this.errors ? "danger" : "success"
				};
				const importData = []

				let keys = [];
  		let d = {};
  		keys = Object.keys(results.data);

  	for (let i = 0; i < keys.length; i++) {
					 d = results.data[i];
					 var user = {
						first: d.first,
						last: d.last,
						email: d.email,
						status: "Not Created"
					}
					if (user.first !== null && user.first !== undefined && user.last !== null && user.last !== undefined && user.email !== null && user.email !== undefined) {
						importData.push(user);
					}
				}
				that.setState({
					uploadData: importData.slice(0),
					feedback
				});
			}
		});
	};

	addToUsersTable = () => {
		this.setState({
			 feedback:""
			})
		const	data = JSON.stringify(this.state.uploadData.slice(0))
		
		const uploadData = JSON.parse(data)
		uploadData.forEach((element) => {
			element.status="Creating"
		})
		this.setState({uploadData:uploadData})
		uploadData.forEach((element, index) => {
			const payload = {
				firstName: element.first,
				lastName: element.last,
				email: element.email,
				productId: 311
			};
	
			//	Handle the form submission
			 const CREATE_USER = {
				query: `mutation createUser($email: String!, $firstName: String!, $lastName: String!, $collegeOrCompanyName: String!, $skuCode: Int!) {
					createUser(email: $email, firstName: $firstName, lastName: $lastName, collegeOrCompanyName: $collegeOrCompanyName, skuCode: $skuCode ){
						id, auth0Id, authIdentity}
				}`,
				variables: {
					email: payload.email,
					firstName: payload.firstName,
					lastName: payload.lastName,
					skuCode: payload.productId,
					collegeOrCompanyName: "Self",
				}
			  }
			  
			  AgilePost(CREATE_USER)
			  .then((res) =>{
				const UserId = res.data.data.createUser.id
				this.assignClassroom(UserId)
				this.addUserStatus("success",index)
			  })
			  .catch((error) => { 
				console.log("error has occured "+error); 
				this.addUserStatus("error",index)

			}) 
		 
		})
	};

addUserStatus = (condition,i) => {
	const data = this.state.uploadData
	if(condition === "success"){
			data[i].status = "Created"
}
	else if( condition === "error"){
		data[i].status = "Exist"
	}
	this.setState({uploadData:data, loading:false})

}


	assignClassroom = (id) => {
		const ASSIGN_USER_CLASSROOM_MUTATION = {query:`
  mutation (
    $id: ID!
    $classroomId: ID
    $welcomeStatus: WelcomeStatus
  ) {
    updateUser(
      id: $id
      classroomId: $classroomId
      welcomeStatus: $welcomeStatus
    ) {
      id
    }
  }
`, variables:{
	id: id,
	classroomId: this.props.currentClassroom.id,
	welcomeStatus:"ASSIGNED"
}}

AgilePost(ASSIGN_USER_CLASSROOM_MUTATION).then(() =>{
	this.setState({loading:false})
  })
  .catch((error) => { 
	console.log("error has occured "+error); 
})
	}

	statusFormatter = (cell) => {
		const cellValue = cell || "";
		switch (cellValue) {
			case "Creating":
				return <Badge color="secondary">{cellValue}</Badge>;
			case "Created":
				return <Badge color="success">{cellValue}</Badge>;
			case "Not Created":
				return <Badge color="info">{cellValue}</Badge>;
			case "Exist":
				return <Badge color="warning">Email ID already exist</Badge>;
			default:
				return cellValue;
		}
	}
	render() {
		
		return (
			<div>
				<UploadHeader>
					<Button outline color="primary">
						<i className="fa fa-upload" /> Upload CSV file

						<input
							type="file"
							accept=".csv"
							onChange={this.handleFileUpload}
							ref={node => {
								this.uploadFileRef = node;
							}}
						/>
		</Button>
					{this.state.feedback ? (
						<Alert color={this.state.feedback.color}>
							{this.state.feedback.text}
						</Alert>
					) : (
						""
					)}
					{this.state.feedback.color === "success" ? (
						<Button
							className="add-users-btn"
							onClick={this.addToUsersTable}
							color="primary"
						>
							Add to Users
						</Button>
					) : (
						""
					)}
				</UploadHeader>
				<StyledBootstrapTable
					data={this.state.uploadData.filter( function(data) {
						return !(data.status === "Created");
					})}
  				striped
  				hover
  				condensed
  				pagination
  				search
				>
					<TableHeaderColumn dataField="first" width="100">
						First Name
					</TableHeaderColumn>
					<TableHeaderColumn dataField="last" width="100">Last Name</TableHeaderColumn>
					<TableHeaderColumn isKey dataField="email" width="150" trStyle={{ whiteSpace: "normal" }} >Email</TableHeaderColumn>
					<TableHeaderColumn dataField="status"  dataFormat={this.statusFormatter} width="100">Account Status</TableHeaderColumn>
				</StyledBootstrapTable>
			</div>
		);
	}
}

export default ImportAgileUsers;
