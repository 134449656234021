import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Alert,
} from "reactstrap";
import styled from "styled-components";
// import FontAwesomeIcon from "@fortawesome/react-fontawesome";
// import { faSpinnerThird } from "@fortawesome/fontawesome-pro-solid";
import Loading from "../../../../components/Loading";
import { AgilePost } from "../../../../services/ApiService";
import { AgileContext } from "../../../../app/store/GlobalState";
import { GET_USERS } from "../../../../app/graphql/queries";

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: end;
  .form-group {
    margin-bottom: 0;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 10px;
  color: black;
`;

const CreateUserFormAgile = (props) => {
  const [state, dispatch] = useContext(AgileContext);
  const [status, setStatus] = useState("");

  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [type, setType] = useState("student");
  const [email, setEmail] = useState("");

  const handleCreateUser = async (e) => {
    e.preventDefault();
    let prod = 311;
    if (type === "instructor") {
      prod = 316;
    }
    //	Handle the form submission
    const CREATE_USER = {
      query: `mutation createUser($email: String!, $firstName: String!, $lastName: String!, $collegeOrCompanyName: String!, $skuCode: Int!) {
                createUser(email: $email, firstName: $firstName, lastName: $lastName, collegeOrCompanyName: $collegeOrCompanyName, skuCode: $skuCode ){
                    id, auth0Id, authIdentity}
            }`,
      variables: {
        email: email,
        firstName: first,
        lastName: last,
        skuCode: prod,
        collegeOrCompanyName: "Self",
      },
    };

    const res = await AgilePost(CREATE_USER);
    
    if (res && res.data && res.data.errors && res.data.errors.length > 0) {
      setStatus(res.data.errors[0].extensions.message);
    }
    else
    {
      setStatus("Created successfully");
    }

    AgilePost(GET_USERS).then((res) => {
      const payload = {
        users: res.data.data.users,
      };
      dispatch({
        type: "RECEIVE_AGILE_USERS",
        payload,
      });
    });
  };

  return (
    <div>
      {status !== "" ? (
        <Alert color="info">
          <b></b>
          {status}
        </Alert>
      ) : (
        ""
      )}

      <StyledForm onSubmit={(evt) => handleCreateUser(evt)}>
        <FormGroup>
            <p>Product Type: Sim Agile </p>
          <Label for="firstName">First Name</Label>
          <Input
            required="required"
            type="text"
            id="firstName"
            onChange={(event) => setFirst(event.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Last Name</Label>
          <Input
            required="required"
            type="text"
            id="lastName"
            onChange={(event) => setLast(event.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <Label for="userEmail">Email</Label>
          <Input
            required="required"
            type="email"
            id="userEmail"
            onChange={(event) => setEmail(event.target.value)}
          />
        </FormGroup>
      
        {/* <FormGroup>
					<Label for="productType">Product Type</Label>
					<Input type="select" ref={(pt) => (productType = pt)} id="productType">
						<option value="simProject">SimProject</option>
						<option value="basicInstructor">Basic Instructor</option>
					</Input>
				</FormGroup> */}
        <FormGroup>
          <Label for="userType">User Account Type</Label>
          <Input
            type="select"
            onChange={(event) => setType(event.target.value)}
            id="userType">
            <option value="student" default="default">
              Student Account
            </option>

            <option value="instructor">Instructor Account</option>
          </Input>
        </FormGroup>

        <Row>
          <Col xs="6">
            <Button color="primary">Save</Button>
          </Col>
          <Col xs="6">
            {state.loading === true ? (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            ) : (
              <span />
            )}
          </Col>
        </Row>
      </StyledForm>
    </div>
  );
};

export default CreateUserFormAgile;
