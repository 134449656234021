import { call, put } from "redux-saga/effects";
import get from "lodash/get";
import { selectToken } from "utilities/tokenManager";
import Actions from "redux/Actions";
import { parseError } from "./Shared";

// change this variable to true to use test data
// const useTestData = false

function* makeFetchUsersRequest(api, action) {
  let response;
  const { resolve, reject } = action;
  const token = sessionStorage.getItem("token"); // yield call(selectToken)

  // if (useTestData) {
  //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
  //   return resolve()
  // }
  if (token) {
    try {
      response = yield call(api.getUsers, token);
    } catch (err) {
      console.log("1", err);
    }

    if (response) {
      const users = get(response, "data");
      yield put(Actions.fetchUsersSuccess(users));
      return resolve();
    }
    const error = parseError(response);
    yield put(Actions.fetchusersFailure(error));
    return reject(error);
  }
  return resolve();
}

function* makeFetchUserRequest(api, action) {
  const { payload: userId, resolve, reject } = action;
  const token = yield call(selectToken);

  if (token) {
    // if (useTestData) {
    //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
    //   return resolve()
    // }

    const response1 = yield call(api.getUser, userId, token);

    if (response1.ok) {
      const user = get(response1, "data");
      yield put(Actions.fetchUserSuccess(user));
      return resolve();
    }
    const error = parseError(response1);
    yield put(Actions.fetchuserFailure());
    return reject(error);
  }
  return reject("no token");
}

function* makeUpdateExpirationRequest(api, action) {
  const {
    payload: { userId, productId, expirationDate },
    resolve,
    reject,
  } = action;

  const token = yield call(selectToken);
  if (token) {
    const payload = {
      userId,
      productId,
      expirationDate,
    };
    const response = yield call(api.updateExpirationDate, payload, token);
    if (response.ok) {
      //const user = get(response, "data")
      yield put(Actions.updateExpirationSuccess());

      //yield put(Actions.setCurrentUser(user))

      return resolve();
    }

    return reject(response);
  }
  return resolve();
}

function* makeUpdateUserEmailRequest(api, action) {
  const { payload, resolve, reject } = action;
  const token = yield call(selectToken);
  if (token) {
    const response = yield call(api.updateUserEmail, payload, token);
    if (response.ok) {
      const user = get(response, "data");
      yield put(Actions.updateUserEmailSuccess(user));

      return resolve();
    }

    return reject(response);
  }
  return resolve();
}

function* makeFetchRunsRequest(api, action) {
  let response;
  const { payload: userId, resolve, reject } = action;

  const token = sessionStorage.getItem("token"); // yield call(selectToken)

  // if (useTestData) {
  //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
  //   return resolve()
  // }
  if (token) {
    try {
      response = yield call(api.getRuns, userId, token);
    } catch (err) {
      console.log("1", err);
    }

    if (response) {
      const runs = get(response, "data");
      yield put(Actions.fetchRunsSuccess(runs));
      return resolve();
    }
    const error = parseError(response);
    yield put(Actions.fetchRunsFailure(error));
    return reject(error);
  }
  return resolve();
}

function* makeBumpRunRequest(api, action) {
  let response;
  const {
    payload: { userId, runId },
    resolve,
    reject,
  } = action;

  const token = sessionStorage.getItem("token"); // yield call(selectToken)

  // if (useTestData) {
  //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
  //   return resolve()
  // }
  //
  if (token) {
    try {
      response = yield call(api.bumpRun, userId, runId, token);
    } catch (err) {
      console.log("1", err);
    }
    if (response) {
      const user = get(response, "data");
      yield put(Actions.bumpRunSuccess(user));
      return resolve();
    }
    const error = parseError(response);
    yield put(Actions.bumpRunFailure(error));
    return reject(error);
  }
  return resolve();
}

function* makeAbandonRunRequest(api, action) {
  let response;
  const {
    payload: { userId, runId },
    resolve,
    reject,
  } = action;

  const token = sessionStorage.getItem("token"); // yield call(selectToken)

  // if (useTestData) {
  //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
  //   return resolve()
  // }
  if (token) {
    try {
      response = yield call(api.abandonRun, userId, runId, token);

      if (response) {
        const user = get(response, "data");
        yield put(Actions.abandonRunSuccess(user));
        return resolve();
      }
      const error = parseError(response);
      yield put(Actions.abandonRunFailure(error));
      return reject(error);
    } catch (err) {
      console.log("1", err);
      return reject(err);
    }
  }
}

// function * fetchUserInfo(api, action) {
//     const {}
// }

// ADD_SAGA_ACTION

export default {
  makeFetchUsersRequest,
  makeFetchUserRequest,
  makeUpdateExpirationRequest,
  makeUpdateUserEmailRequest,
  makeFetchRunsRequest,
  makeBumpRunRequest,
  makeAbandonRunRequest,
  // EXPORT_SAGA_ACTION
};
