import React, { Component, Fragment } from "react";
import { Input, Button, Row, Col } from "reactstrap";
import { AgilePost } from "../../../../services/ApiService";

export default class EditAgileClassName extends Component {
  saveClassroomChange = async () => {
    const UPDATE_CLASSROOM_MUTATION = {
      query: `mutation updateClassroom(
        $id: Int!
        $className: String!
      ) {
        updateClassroom(
            id:$id,
            className:$className
        ) {
          id
        }
      }`,
      variables: {
        id: this.props.currentClassroom.id,
        className: this.className.value,
      },
    };
    return AgilePost(UPDATE_CLASSROOM_MUTATION).then((response) => {
      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Row>
          <Col xs="4" sm="6" lg="9">
            <Input
              type="text"
              name="className"
              className="mb-2 mr-sm-2 mb-sm-0"
              defaultValue={this.props.currentClassroom.className}
              innerRef={(cn) => (this.className = cn)}
              style={{
                flex: "350px 0",
              }}
            />
          </Col>
          <Col width="10%">
            <Button
              xs="2"
              sm="2"
              lg="3"
              color="primary"
              onClick={(e) => this.saveClassroomChange(e)}>
              Update
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
