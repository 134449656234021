import React, { Component } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import StyledBootstrapTable from "components/styled/BootstrapTable.js";
import {Row,Col, Card,CardHeader, CardBody } from "reactstrap";
import styled from "styled-components";

const StyledTableHeaderRow = styled(Row)`
  .search {
    margin-bottom: 1.5rem;
  }
`;

export default class InstructorReport extends Component {

    createCustomToolBar = props => {
        let totalstudents = 0;
        this.props.instructorData.forEach( i => {
            totalstudents += i.studentsLength
            
        });
        return (
        <Col>
          <StyledTableHeaderRow>
            <Col sm="12" md={{ size: 6, offset: 6 }}>
              <div className="search">{props.components.searchPanel}</div>
            </Col>
            <Col>
    <b>Total Instructor: </b>{this.props.instructorData.length}
    <br />
    <b>Total students: </b>{totalstudents}
            </Col>
          </StyledTableHeaderRow>
        </Col>
      );
        }
    render(){

 
	
        const tableOptions = {
            toolBar: this.createCustomToolBar,
			clearSearch: true,
			sizePerPage: 10,
			sizePerPageList: [
				{
					text: "10",
					value: 10
				},
				{
					text: "20",
					value: 20
				},
				{
					text: "30",
					value: 30
				}
            ],
		};
        return(
            <div>
                <Card>
                    <CardHeader>
Students By Instructor
                    </CardHeader>
                    <CardBody>
                    <StyledBootstrapTable
            data={this.props.instructorData}
            options={tableOptions}
			hover
            search
            striped
			pagination
		>
			<TableHeaderColumn width="15%" align="center" isKey dataField="instructorName"  dataSort>
			 Instructor Name
			</TableHeaderColumn>
            <TableHeaderColumn align="center" dataField="email" dataSort>
			Email
			</TableHeaderColumn>
            <TableHeaderColumn align="center" dataField="classroomLength" dataSort>
			No. Of Classrooms
			</TableHeaderColumn>
			<TableHeaderColumn align="center" dataField="studentsLength" dataSort>
			No. Of students
			</TableHeaderColumn>
			
		</StyledBootstrapTable>
                    </CardBody>
                </Card>
           
</div>
        )
    }
};