import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import styled from "styled-components";
import Select from "react-select";
import debounce from "lodash/debounce";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "static/styles/reactDatepicker.css";

// import { sampleInstructors } from "../../../../utilities/sampleData";

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: end;

  .form-group {
    margin-bottom: 0;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker-wrapper {
    /* display: inline-block; */
    padding: 0;
    border: 0;
  }
`;
class CreatePmClassroomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primaryInstructor: {},
      secondaryInstructor: {},
      disableSaveButton: true,
      formStatus: "Ready to add",
      instructors: this.props.instructors,
      paginate: true,
      startDate: "",
      endDate: "",
      disableDatepicker: true,
    };
    this.handlePrimaryChange = this.handlePrimaryChange.bind(this);
    this.handleCreateClassroom = this.handleCreateClassroom.bind(this);
    this.changeStartdate = this.changeStartdate.bind(this);
    this.changeEnddate = this.changeEnddate.bind(this);
  }

  handlePrimaryChange = (selected) => {
    if (selected === undefined || selected.length < 1) {
      this.setState({ disableSaveButton: true });
      return;
    } else {
      this.setState({ primaryInstructor: selected, disableSaveButton: false });
    }
  };

  changeStartdate = (date) => {
    this.setState({
      startDate: moment(date).format("YYYY-MM-DD"),
      disableSaveButton: false,
      disableDatepicker: false,
    });
  };

  changeEnddate = (date) => {
    this.setState({
      endDate: moment(date).format("YYYY-MM-DD"),
      disableSaveButton: false,
    });
  };

  handleCreateClassroom = debounce(() => {
    if (
      this.state.startDate === undefined ||
      this.state.startDate === null ||
      this.state.startDate === ""
    ) {
      this.setState({
        disableSaveButton: true,
        formStatus: "Select a Startdate",
      });
      return;
    }

    if (
      this.state.endDate === undefined ||
      this.state.endDate === null ||
      this.state.endDate === ""
    ) {
      this.setState({
        disableSaveButton: true,
        formStatus: "Select a Enddate",
      });
      return;
    }

    if (
      this.state.primaryInstructor === undefined ||
      this.state.primaryInstructor === null ||
      this.state.primaryInstructor.value === undefined ||
      this.state.primaryInstructor.value === null
    ) {
      this.setState({
        disableSaveButton: true,
        formStatus: "Please choose an instructor ...",
      });
      return;
    }
    this.setState({ disableSaveButton: true, formStatus: "Adding ..." });

    // Handle the form submission
    var payload = {
      ProductId: 43,
      ClassName: this.className.value,
      TeamType: 0,
      MaxStudents: 100,
      RunsPerStudent: Number.parseInt(this.classRuns.value, 10),
      MaxTeamSize: 10,
      ClassroomType: 0,
      ClassroomStatus: this.classroomStatus.value,
      InstructorId: this.state.primaryInstructor.value,
      StartDate: this.state.startDate,
      EndDate: this.state.endDate,
    };
    this.props
      .addClassroom(payload)
      .then(() => {
        this.setState({
          primaryInstructor: {},
          disableSaveButton: true,
          formStatus: "Success ...",
          startDate: "",
          endDate: "",
        });
        this.className.value = "";
        this.classRuns.value = 3;
        this.classroomStatus.value = "";
        this.props.toggleClose();
      })
      .then(() => {
        this.setState({
          formStatus: "Ready to add",
          disableSaveButton: true,
          disableDatepicker: true,
        });
      })

      .catch((err) => {
        this.setState({ disableSaveButton: false, formStatus: err.message() });
      });
  }, 75);

  render() {
    return (
      <StyledForm>
        <p>Status: {this.state.formStatus} </p>
        <FormGroup>
          <Label for="className">Class Name</Label>
          <Input
            required
            type="text"
            id="className"
            innerRef={(cn) => (this.className = cn)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="productType">Product Type</Label>
          <Input
            type="select"
            id="productType"
            innerRef={(pt) => (this.productType = pt)}
            disabled="disabled"
          >
            <option value="pm" default>
              PM Fundamentals
            </option>
            {/* <option value="SimProject" default>
              SimProject
            </option>
            <option value="basicProjectManagement">
              Basic Project Management
            </option>
            <option value="scrumAgile">Scrum Agile</option> */}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="primaryInstructor">Primary Instructor</Label>

          <Select
            options={this.props.instructors}
            placeholder="Choose an instructor ..."
            onChange={(selected) => {
              this.handlePrimaryChange(selected);
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="startDate">Start Date</Label>
          <div className="customDatePickerWidth">
            <StyledDatePicker
              onChange={this.changeStartdate}
              minDate={moment().toDate()}
              value={
                this.state.startDate !== ""
                  ? moment(this.state.startDate).format("YYYY-MM-DD")
                  : "Select a Date"
              }
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Label for="endDate">End Date</Label>
          <div className="customDatePickerWidth">
            <StyledDatePicker
              disabled={this.state.disableDatepicker}
              onChange={this.changeEnddate}
              minDate={moment(this.state.startDate).toDate()}
              placeholderText="Select a day"
              value={
                this.state.startDate === ""
                  ? "Select the Start Date"
                  : this.state.endDate !== ""
                  ? moment(this.state.endDate).format("YYYY-MM-DD")
                  : "Select a Date"
              }
            />
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="classRuns">Runs per student</Label>
          <Input
            type="number"
            id="classRuns"
            min={1}
            defaultValue={3}
            innerRef={(cr) => (this.classRuns = cr)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="classroomStatus">Status</Label>
          <Input
            type="select"
            innerRef={(cs) => (this.classroomStatus = cs)}
            id="classroomStatus"
          >
            <option value="Registration" default="default">
              Registration
            </option>
            <option value="Running">Running</option>
          </Input>
        </FormGroup>
        <Button
          color="primary"
          disabled={this.state.disableSaveButton}
          onClick={this.handleCreateClassroom}
        >
          Save
        </Button>
      </StyledForm>
    );
  }
}

export default CreatePmClassroomForm;
