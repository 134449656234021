/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import { AdminGet } from "../../../services/ApiService";
import { Button, InputGroup, InputGroupAddon, Input } from "reactstrap";
import styles from "../Dashboard.module.css";

const ActiveInstructorsMetric = () => {
  const today = new Date();
  const defaultFromDate = new Date(today.setFullYear(today.getFullYear() - 1))
    .toISOString()
    .split("T")[0];
  const defaultToDate = new Date(today.setFullYear(today.getFullYear() + 2))
    .toISOString()
    .split("T")[0];

  const [data, setData] = useState([["Date", "Simproject", "Agile"]]);
  const [fromDate, setFromDate] = useState(defaultFromDate);
  const [toDate, setToDate] = useState(defaultToDate);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchData = async () => {
    setLoading(true);
    const response = await AdminGet(
      `Dashboard/ActiveInstructors?FromDate=${fromDate}&ToDate=${toDate}`
    );
    const apiData = response.data.map((item) => [
      item.date,
      item.simprojectInstructors,
      item.agileInstructors,
    ]);
    setData([["Date", "Simproject", "Agile"], ...apiData]);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();   
  }, []);

  const handleRefresh = () => {
    if (new Date(toDate) <= new Date(fromDate)) {
      setError("To date must be later than from date.");
      return;
    }
    setError("");
    fetchData();
  };

  const options = {
    title: "Unique Active Instructors",
    titleTextStyle: { 
      color: '#333', 
      fontSize: 16,
      fontName: 'Verdana'
    },
    lineWidth: 2,
    pointSize: 2,
    hAxis: {
      title: "Date",
      format: "MMM dd, yyyy",
      gridlines: { count: -1 },
      textStyle: { 
        color: '#333',
        fontName: 'Verdana' ,
        fontSize: 12,
      } 
    },
    vAxis: {
      title: "Instructors",
      textStyle: { 
        color: '#333',
        fontName: 'Verdana',
        fontSize: 12,  
        italic: false
      } 
    },
    series: {
      0: { color: "red" },
      1: { color: "green" },
      textStyle: { 
        color: '#333',
        fontName: 'Verdana',
        fontSize: 12, 
      } 
    },
  };

  return (
    <div className={styles.chartContainer}>
      <InputGroup>
        <Input
          className={`${styles.inputFilter} ${styles.inputDateFilter}`}
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        />
        <Input
          className={`${styles.inputFilter} ${styles.inputDateFilter}`}
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        />
        <InputGroupAddon addonType="prepend">
          <Button
            color="primary"
            onClick={handleRefresh}
            className={styles.refreshButton}
          >
            <i className="fa fa-refresh" />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      {error && <div className={styles.errorLabel}>{error}</div>}
      {loading ? (
        <div className={styles.loadingLabel}>Loading...</div>
      ) : (
        <Chart
          chartType="LineChart"
          data={data}
          options={options}
          width="100%"
          height="400px"
          legendToggle
        />
      )}
    </div>
  );
};

export default ActiveInstructorsMetric;
