const adminUsersData =
[
  {
    "userId": 0,
    "first": "Lori",
    "last": "Tillery",
    "email": "lori_tillery@simulationpl.com",
  },
  {
    "userId": 1,
    "first": "Jesse",
    "last": "Freese",
    "email": "jesse_freese@simulationpl.com",
  },
  {
    "userId": 2,
    "first": "Donette",
    "last": "Gardner",
    "email": "donette_gardner@simulationpl.com",
  }


  ]
export default adminUsersData
