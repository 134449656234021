import React, { Component, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get } from "lodash";
import Actions from "redux/Actions";
import classnames from "classnames";
// import FontAwesomeIcon from "@fortawesome/react-fontawesome";
// import { faSpinnerThird } from "@fortawesome/fontawesome-pro-solid";
import Loading from "../../components/Loading";
import styled from "styled-components";
// import ClassroomsListCard from "./components/ClassroomsListCard/ClassroomsListCard";
import ClassroomsTable from "./components/ClassroomsTable/ClassroomsTable";
import PaccissClassroomsTable from "./components/PaccissClassroomComponent/PaccissClassroomsTable";
import PmClassroomsTable from "./components/PmClassroomComponent/PmClassroomsTable";
import AgileClassroomTable from "./components/AgileComponents/AgileClassroomTable";
import CreateClassroomForm from "./components/CreateClassroomForm/CreateClassroomForm";
import CreatePaccissClassroomForm from "./components/PaccissClassroomComponent/CreatePaccissClassroomForm";
import CreatePmClassroomForm from "./components/PmClassroomComponent/CreatePmClassroomForm";
import ImportClassrooms from "./components/ImportClassrooms/ImportClassrooms";
import ImportPaccissClassrooms from "./components/PaccissClassroomComponent/ImportPaccissClassrooms";
import ImportPmClassrooms from "./components/PmClassroomComponent/ImportPmClassrooms";
import {
  instructorOptions,
  paccissClassroomOptions,
  pmClassroomOptions,
  simInstructorOptions,
  paccissInstructorOptions,
  pmInstructorOptions,
  simClassroomOptions,
  paccissNewClassroomOptions,
  paccissNewInstructorOptions
} from "redux/selectors";
import PaccissNewClassroomsTable from "./components/PaccissClassroomComponent/PaccissNewClassroomsTable";
import CreatePaccissNewClassroomForm from "./components/PaccissClassroomComponent/CreatePaccissNewClassroomForm";
import ImportPaccissNewClassrooms from "./components/PaccissClassroomComponent/ImportPaccissNewClassrooms";

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: black;

  svg {
    margin-right: 1rem;
  }
`;

class Classrooms extends Component {
  state = {
    modal: false,
    paccissmodal: false,
    pmsmodal: false,
    paccissnewmodal: false,
    loading: true,
    activeClassDetailTab: "agile",
    classrooms: [],
    instructors: [],
    organizations: [],
  };

  componentDidMount() {
    Promise.all([
      this.props.fetchClassrooms(),
      this.props.fetchInstructors(),
    ]).then(
      this.setState({
        loading: false,
      })
    );
  }

  toggleModal = (content) => {
    this.setState({
      modal: !this.state.modal,
      modalContent: content,
    });
  };
  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  paccissToggleModal = (content) => {
    this.setState({
      paccissmodal: !this.state.paccissmodal,
      paccissmodalContent: content,
    });
  };
  pmToggleModal = (content) => {
    this.setState({
      pmmodal: !this.state.pmmodal,
      pmmodalContent: content,
    });
  };
  paccissNewToggleModal = (content) => {
    this.setState({
      paccissnewmodal: !this.state.paccissnewmodal,
      paccissnewmodalContent: content,
    });
  };
  onRefreshList = () => {
    Promise.all([
      this.setState({ loading: true }),
      this.props.fetchClassrooms(),
      this.props.fetchInstructors(),
    ]).then(() => {
      this.setState({ loading: false });
    });
  };
  toggleTab = (tab) => {
    if (this.state.activeClassDetailTab !== tab) {
      this.setState({
        activeClassDetailTab: tab,
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "sim",
                  })}
                  onClick={() => {
                    this.toggleTab("sim");
                  }}
                >
                  SimProject
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "agile",
                  })}
                  onClick={() => {
                    this.toggleTab("agile");
                  }}
                >
                  SimAgile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "pacciss",
                  })}
                  onClick={() => {
                    this.toggleTab("pacciss");
                  }}
                >
                  Pacciss
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "pm",
                  })}
                  onClick={() => {
                    this.toggleTab("pm");
                  }}
                >
                  PM Fundamentals
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "paccissnew",
                  })}
                  onClick={() => {
                    this.toggleTab("paccissnew");
                  }}
                >
                  Pacciss 14
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeClassDetailTab}>
              <TabPane tabId="sim">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <ClassroomsTable
                        openModal={this.toggleModal}
                        onRefreshList={this.onRefreshList}
                        simClassroomList={this.props.simClassroomList}
                      />
                    )}

                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size="lg"
                    >
                      <ModalHeader toggle={this.toggleModal}>
                        {this.state.modalContent === "create-classroom"
                          ? "Create a new classroom"
                          : "Import classrooms"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.modalContent === "create-classroom" ? (
                          <CreateClassroomForm
                            instructors={this.props.simInstructorList}
                            addClassroom={this.props.addClassroom}
                          />
                        ) : (
                          <ImportClassrooms />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="agile">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <AgileClassroomTable />
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="pacciss">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <PaccissClassroomsTable
                        openModal={this.paccissToggleModal}
                        onRefreshList={this.onRefreshList}
                        instructorList={this.props.instructorList}
                        paccissClassroomList={this.props.paccissClassroomList}
                      />
                    )}

                    <Modal
                      isOpen={this.state.paccissmodal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size="lg"
                    >
                      <ModalHeader toggle={this.paccissToggleModal}>
                        {this.state.paccissmodalContent ===
                        "create-pacciss-classroom"
                          ? "Create a new classroom"
                          : "Import classrooms"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.paccissmodalContent ===
                        "create-pacciss-classroom" ? (
                          <CreatePaccissClassroomForm
                            instructors={this.props.paccissInstructorList}
                            addClassroom={this.props.addClassroom}
                            toggleClose={this.paccissToggleModal}
                          />
                        ) : (
                          <ImportPaccissClassrooms />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="secondary"
                          onClick={this.paccissToggleModal}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="pm">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <PmClassroomsTable
                        openModal={this.pmToggleModal}
                        onRefreshList={this.onRefreshList}
                        instructorList={this.props.instructorList}
                        pmClassroomList={this.props.pmClassroomList}
                      />
                    )}

                    <Modal
                      isOpen={this.state.pmmodal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size="lg"
                    >
                      <ModalHeader toggle={this.pmToggleModal}>
                        {this.state.pmmodalContent === "create-pm-classroom"
                          ? "Create a new classroom"
                          : "Import classrooms"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.pmmodalContent === "create-pm-classroom" ? (
                          <CreatePmClassroomForm
                            instructors={this.props.pmInstructorList}
                            addClassroom={this.props.addClassroom}
                            toggleClose={this.pmToggleModal}
                          />
                        ) : (
                          <ImportPmClassrooms />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={this.pmToggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="paccissnew">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <PaccissNewClassroomsTable
                        openModal={this.paccissNewToggleModal}
                        onRefreshList={this.onRefreshList}
                        instructorList={this.props.instructorList}
                        paccissNewClassroomList={this.props.paccissNewClassroomList}
                      />
                    )}

                    <Modal
                      isOpen={this.state.paccissnewmodal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size="lg"
                    >
                      <ModalHeader toggle={this.paccissNewToggleModal}>
                        {this.state.paccissnewmodalContent ===
                        "create-pacciss-new-classroom"
                          ? "Create a new classroom"
                          : "Import classrooms"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.paccissnewmodalContent ===
                        "create-pacciss-new-classroom" ? (
                          <CreatePaccissNewClassroomForm
                            instructors={this.props.paccissNewInstructorList}
                            addClassroom={this.props.addClassroom}
                            toggleClose={this.paccissNewToggleModal}
                          />
                        ) : (
                          <ImportPaccissNewClassrooms />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="secondary"
                          onClick={this.paccissNewToggleModal}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  classrooms: (state) => get(state, "classroom.classrooms"),
  instructors: (state) => get(state, "classroom.instructors"),
  instructorList: (state) => instructorOptions(state),
  paccissClassroomList: (state) => paccissClassroomOptions(state),
  paccissNewClassroomList: (state) => paccissNewClassroomOptions(state),
  pmClasroomList: (state) => pmClassroomOptions(state),
  simInstructorList:(state) => simInstructorOptions(state),
  paccissInstructorList:(state) => paccissInstructorOptions(state),
  paccissNewInstructorList:(state) => paccissNewInstructorOptions(state),
  pmInstructorList:(state) => pmInstructorOptions(state),
  simClassroomList:(state) => simClassroomOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchClassrooms: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchClassroomsRequest(resolve, reject))
    ),

  addClassroom: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.addClassroomRequest(payload, resolve, reject))
    ),

  fetchInstructors: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchInstructorsRequest(resolve, reject))
    ),
  fetchUser: (userId) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchUserRequest(userId, resolve, reject))
    ),
  fetchRuns: (userId) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchRunsRequest(userId, resolve, reject))
    ),
  fetchMembershipUser: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchMembershipUserRequest(payload, resolve, reject))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Classrooms);
