import { Row } from 'reactstrap';
import styled from 'styled-components';


const StyledTableHeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;

  > .col:first-child .btn {
    margin-right: 0px;
  }

  > .col:last-child {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .search {
    margin-top: 0.5rem;
  }
`;
export default StyledTableHeaderRow;
