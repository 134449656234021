import {createStore, applyMiddleware, compose} from "redux"
import rootReducer from "app/reducers/";
import {routerMiddleware} from "react-router-redux";
import createSagaMiddleware from "redux-saga"
import SagaManager from "sagas/SagaManager";
import {createLogger} from "redux-logger";
import history from "app/store/history";
import {saveTokenToLocalStorage} from "utilities/tokenManager"

/* eslint-disable no-underscore-dangle */
const initialState = window.___INITIAL_STATE__;
/* eslint-enable */

// creates the store

/* ------------- Redux Configuration ------------- */

const middlewares = []
const storeEnhancers = []

/* ------------- Logger Middleware ------------- */
if (process.env.NODE_ENV !== "production") {
  const logger = createLogger({collapsed: true})
  middlewares.push(logger)
}
/* ------------- Analytics Middleware ------------- */

/* ------------- Saga Middleware ------------- */

const sagaMonitor = null
const sagaMiddleware = createSagaMiddleware({sagaMonitor})
middlewares.push(sagaMiddleware)
middlewares.push(routerMiddleware(history))

/* ------------- Assemble Middleware ------------- */
const middlewareEnhancer = applyMiddleware(...middlewares);
storeEnhancers.unshift(middlewareEnhancer)

saveTokenToLocalStorage("aaaaaaaaaaaaaaaa")

export default function configureStore() {
  const store = createStore(rootReducer, initialState, compose(...storeEnhancers));

  // run sagas
  SagaManager.startSagas(sagaMiddleware);
  if (process.env.NODE_ENV !== "production") {
    // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
    if (module.hot) {
      module.hot.accept("app/reducers/index", () => store.replaceReducer(rootReducer));

      module.hot.accept("sagas/SagaManager", () => {
        SagaManager.cancelSagas(store);
        SagaManager.startSagas(sagaMiddleware);
      });
    }
  }

  return store;
}
