export const GET_USERS = {
  query: `
		{
		  users {
			  id,
			  lastName
			  firstName
			  email
			  role
			  welcomeStatus
			  licenses{
				registrationDate
				expirationDate
			  }
		  }
	  } 
		`,
};

export const CLASSROOMS_QUERY = {
  query: ` {
		classrooms{
			id
			className
			startDate
			endDate
			status
			students {
				id    
			}
			instructors {
				type
				user {
					id
					firstName
					lastName
				}
			}
			classStudentRuns {
				id
				student {
					id
					firstName
					lastName
					email
				}
			}
		}
	}
  `,
};

export const INSTRUCTORS_QUERY = {
  query: ` {
     users(where: {role: {eq: INSTRUCTOR}}) {
      id
      firstName
      lastName
    }
  }
`};
