import React, { useState } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get } from "lodash";
import Actions from "redux/Actions";
import StyledBootstrapTable from "../../../../components/styled/BootstrapTable";

const UserRunTable = (props) => {
  const [message, setMessage] = useState("");

  const bumpRunClick = (row) => {
    const payload = {
      userId: row.userId,
      runId: row.runId,
    };

    if (row.runId > 0) {
      setMessage("");
      props.bumpRun(payload);
    } else {
      setMessage("Cannot bump this run, it has a 0 runId");
    }
  };

  const abandonRunClick = (row) => {
    const payload = {
      userId: row.userId,
      runId: row.runId,
    };
    props.abandonRun(payload);
  };

  const formatActions = (cell, row, formatExtraData, rowIdx) => {
    if (row.runId > 0) {
      return (
        <div>
          <Button
            color="link"
            onClick={() => {
              bumpRunClick(row);
            }}
          >
            Bump
          </Button>
          <Button
            color="link"
            onClick={() => {
              abandonRunClick(row);
            }}
          >
            Abandon
          </Button>
        </div>
      );
    }
    return <span />;
  };

  const options = {
    noDataText: "Student has no runs in the database",
  };

  return (
    <div>
      <StyledBootstrapTable data={props.runs} options={options}>
        <TableHeaderColumn width="15%" dataField="runId">
          Run Id
        </TableHeaderColumn>

        <TableHeaderColumn width="15%" isKey dataField="runSequenceNumber">
          Seq#
        </TableHeaderColumn>

        <TableHeaderColumn width="30%" dataField="runStatus">
          Run Status
        </TableHeaderColumn>
        <TableHeaderColumn
          width="40%"
          tourstop="actionHeader"
          className="col-last"
          editable={false}
          columnClassName="col-last"
          dataAlign="center"
          dataFormat={formatActions}
        >
          <div tourstop="actionHeader">Actions</div>
        </TableHeaderColumn>
      </StyledBootstrapTable>

      <footer>
        <p style={{ color: "red" }}>{message}</p>
      </footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  runs: (state) => get(state, "users.currentUser.classStudent.studentRuns"),
  currentUser: (state) => get(state, "users.currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  abandonRun: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.abandonRunRequest(payload, resolve, reject))
    ),
  bumpRun: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.bumpRunRequest(payload, resolve, reject))
    ),
  fetchRuns: (userId) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchRunsRequest(userId, resolve, reject))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRunTable);
