import { call, put } from "redux-saga/effects";
import get from "lodash/get";
import Actions from "redux/Actions";
import { parseError } from "./Shared";

function* makeFetchMembershipUsersRequest(api, action) {
	let response;
	const { resolve, reject } = action;

	try {
		response = yield call(api.getAllUsers);
	} catch (err) {
		console.log("1", err);
	}
	if (response) {
		const users = get(response, "data");
		yield put(Actions.fetchMembershipUsersSuccess(users));
		return resolve();
	}
	const error = parseError(response);
	yield put(Actions.fetchMembershipusersFailure(error));
	return reject(error);
}

function* makeFetchMembershipUserRequest(api, action) {
	const { payload: userId, resolve, reject } = action;

	// if (useTestData) {
	//   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
	//   return resolve()
	// }

	const response = yield call(api.getMembershipUser, userId);

	if (response.ok) {
		const user = get(response, "data.getUserByIdResult.payload");
		yield put(Actions.fetchMembershipUserSuccess(user));
		return resolve();
	}
	const error = parseError(response);
	yield put(Actions.fetchMembershipUsersFailure(error));
	return reject();
}

function* makeCheckMbrUserStatusRequest(api, action) {
	const { payload: username, resolve, reject } = action;

	// if (useTestData) {
	//   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
	//   return resolve()
	// }

	const response = yield call(api.checkMembershipUserStatus, username);

	if (response.ok) {
		const user = get(response, "data.getUserByUsernameResult.payload");

		yield put(Actions.fetchMembershipUserSuccess(JSON.parse(user)));
		return resolve();
	}
	const error = parseError(response);
	yield put(Actions.fetchMembershipUsersFailure(error));
	return reject();
}

function* makeAddUserRequest(api, action) {
  const { payload, resolve, reject } = action;
 
  const response = yield call(api.addNewUser, payload);
	if (response.ok) {
		yield put(Actions.addUserSuccess(response));
		return resolve("ok");
	}
	return reject();
}

function* makeAdminResetPasswordRequest(api, action) {
	const { payload, resolve, reject } = action;
	const response = yield call(api.adminResetPassword, payload);
	if (response.ok) {
		yield put(Actions.adminResetPasswordSuccess());

		return resolve();
	}
	return reject();
}

function* makeUpdateEmailRequest(api, action) {
	const { payload, resolve, reject } = action;
	const response = yield call(api.changeEmail, payload);

	if (response.ok) {
		var data = JSON.parse(response.data.changeEmailResult.payload);
		
		yield put(Actions.updateEmailSuccess(data));
		return resolve();
	}
	const error = parseError(response);
	yield put(Actions.updateEmailFailure(error));
	return reject(error);
}

function* makeBulkAddUsersRequest(api, action) {
	const { payload, resolve, reject } = action;

	const response = yield call(api.bulkAddNewUsers, payload);
	if (response.ok) {
		yield put(Actions.fetchUsersRequest(resolve, reject));
		return resolve();
	}
	return reject();
}

// ADD_SAGA_ACTION

export default {
	makeFetchMembershipUsersRequest,
	makeFetchMembershipUserRequest,
	makeCheckMbrUserStatusRequest,
	makeAddUserRequest,
	makeAdminResetPasswordRequest,
	makeUpdateEmailRequest,
	makeBulkAddUsersRequest
	// EXPORT_SAGA_ACTION
};
