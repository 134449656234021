import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import styled from "styled-components";
// import FontAwesomeIcon from "@fortawesome/react-fontawesome";
// import { faSpinnerThird } from "@fortawesome/fontawesome-pro-solid";
import Loading from "../../../../components/Loading";

const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: end;

  .form-group {
    margin-bottom: 0;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;

  justify-content: center;
  align-items: center;
  min-height: 10px;
  color: black;
`;
class CreatePaccissNewUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      addResult: "",
    };
  }

  handleCreateUser = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const uname = this.firstName.value + this.lastName.value;
    const utype = Number.parseInt(this.userType.value, 10) || 0;
    let prod = 600; // student - type 0 or 80
    if (utype === 89) {
      prod = 601; // instructor product
    } 
    // else if (utype === 99) {
    //   prod = 152; // admin product
    // }

    const payload = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      username: uname.replace(/\s/g, ""),
      userType: utype,
      email: this.email.value,
      productId: prod,
      phoneNumber: this.phoneNumber.value,
      purchaseOrderId: this.orderNumber.value,
    };

    const response = await this.props.addUser(payload);
    if (response === "ok") {
      this.setState({
        addResult: "User Added",
      });
      await this.props.refreshList();
      this.props.toggleClose();
      this.props.closePopup()
    } else {
      this.setState({
        addResult: "Something went wrong, please check with support",
      });
    }
    //	Handle the form submission
  };

  render() {
    return (
      <StyledForm onSubmit={(evt) => this.handleCreateUser(evt)}>
        <FormGroup>
          <Label for="firstName">First Name</Label>
          <Input
            required="required"
            type="text"
            id="firstName"
            innerRef={(fn) => (this.firstName = fn)}
            onChange={this.setUsername}
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastName">Last Name</Label>
          <Input
            required="required"
            type="text"
            id="lastName"
            innerRef={(ln) => (this.lastName = ln)}
            onChange={this.setUsername}
          />
        </FormGroup>

        <FormGroup>
          <Label for="userPhone">Phone Number</Label>
          <Input
            type="tel"
            id="userPhone"
            innerRef={(pn) => (this.phoneNumber = pn)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="userEmail">Email</Label>
          <Input
            required="required"
            type="email"
            id="userEmail"
            innerRef={(em) => (this.email = em)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="productType">Product Type</Label>
          <Input
            type="select"
            innerRef={(pt) => (this.productType = pt)}
            id="productType"
            disabled="disabled"
          >
            <option value="Pacciss14" default="default">
              Pacciss14
            </option>
            {/* <option value="simProject">SimProject</option>
            <option value="basicInstructor">Basic Instructor</option> */}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="userType">User Account Type</Label>
          <Input
            type="select"
            innerRef={(ut) => (this.userType = ut)}
            id="userType"
          >
            <option value="0" default="default">
              Student Account
            </option>
            {/* <option value="80">Instructor Student Account</option> */}
            <option value="89">Instructor Account</option>
            {/* <option value="99">Fissure Account</option> */}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="userOrderNumber">Order Number</Label>
          <Input
            type="text"
            id="userOrderNumber"
            innerRef={(on) => (this.orderNumber = on)}
          />
        </FormGroup>
        <Row>
          <Col xs="6">
            <Button color="primary">Save</Button>
          </Col>
          <Col xs="6">
            {this.state.loading === true ? (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            ) : (
              <p>{this.state.addResult}</p>
            )}
          </Col>
        </Row>
      </StyledForm>
    );
  }
}

export default CreatePaccissNewUserForm;
