
import { isEmpty } from "lodash"

const currentUser = async () => {
  const user = await sessionStorage.getItem("currentUser")
  return (isEmpty(user) ? {} : JSON.parse(user))
}

const selectToken = async () => {
  // console.log("here")
  const t = await sessionStorage.getItem("token")
  // console.log(t)
  return(isEmpty(t) ? "" : t)
}

const saveUserToLocalStorage = async (user) => {
  await sessionStorage.setItem("currentUser", JSON.stringify(user))

}

const saveTokenToLocalStorage =  (res) => {

  if (res && res.data) {

   sessionStorage.setItem("token", res.data.access_token);
   sessionStorage.setItem("token_type", res.data.token_type);
   sessionStorage.setItem("expires_in", res.data.expires_in);
   }
}

const removeTokenFromLocalStorage = () => {
  sessionStorage.setItem("token", "")
  sessionStorage.setItem("token_type", "");
  sessionStorage.setItem("expires_in", "");

}



const generateUniqueId = () =>(new Date().getMilliseconds().toString()+Math.random())

export default currentUser
export {
  selectToken,
  saveTokenToLocalStorage,

  saveUserToLocalStorage,
  removeTokenFromLocalStorage,
  generateUniqueId,
};
