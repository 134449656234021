import React, { Component } from "react";
import { connect } from "react-redux";
import history from "app/store/history"

export default function(ComposedComponent) {
  class Authentication extends Component {

    componentDidMount() {
      if (!this.props.authenticated) {
     const url = "/login"
      history.push(url);
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { authenticated: state.auth.authenticated };
  }

  return connect(mapStateToProps)(Authentication);
}
