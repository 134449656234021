/**
* Vanilla JS version on jQuery's nextUntil()
* https://github.com/cferdinandi/nextUntil
*/
export const nextUntil = (elem, selector, filter) => {
	// matches() polyfill
	if (!Element.prototype.matches) {
		Element.prototype.matches =
			Element.prototype.msMatchesSelector ||
			Element.prototype.webkitMatchesSelector;
	}
	// Setup siblings array
	const siblings = [];
	// Get the next sibling element
  elem = elem.nextElementSibling;
	// As long as a sibling exists
	while (elem) {
		// If we've reached our match, bail
		if (elem.matches(selector)) break;

		// If filtering by a selector, check if the sibling matches
		if (filter && !elem.matches(filter)) {
			elem = elem.nextElementSibling;
			continue;
		}
		// Otherwise, push it to the siblings array
		siblings.push(elem);
		// Get the next sibling element
		elem = elem.nextElementSibling;
	}
	return siblings;
};

export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
export const formatEnumValue = value => {
  if (value === undefined || value === null) return "";

  let enumValues = [];

  // assuming that words in enum entry is formed by _ character
  if (value.indexOf("_")) {
    const words = value.split("_");
    enumValues = enumValues.concat(words);
  } else {
    enumValues.push(value);
  }

  // convert every word to title case
  const titleCaseWords = enumValues.map(v => {
    return toTitleCase(v);
  });

  const formattedString = titleCaseWords.join(" ");
  return formattedString;
};
