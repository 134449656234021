import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get } from "lodash";
import Actions from "redux/Actions";

import { Link } from "react-router-dom";
import { TableHeaderColumn } from "react-bootstrap-table";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { paccissNewUsers } from "redux/selectors";
import { Badge } from "reactstrap";
import moment from "moment";
import StyledBootstrapTable from "../../../../components/styled/BootstrapTable.js";

class UsersTable extends Component {
  constructor(props) {
    super(props);
    // need to use state variable for  list because props.users
    // is immutable and you can't sort an immutable list
    this.state = {
      paccissNewUsersList: [...this.props.paccissNewUsersList],
    };

    this.setCurrentUser = this.setCurrentUser.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.paccissNewUsersList !== undefined &&
      nextProps.paccissNewUsersList !== this.props.paccissNewUsersList
    ) {
      this.setState({ paccissNewUsersList: [...nextProps.paccissNewUsersList] });
    }
  }

  setCurrentUser = (event, user) => {
    this.props
      .checkMbrUserStatus(user.nickName)
      .then(() => {
        this.props.fetchUser(user.userId);
        // try {
        //   this.props.fetchMembershipUser(user.userId).then(() => {
        //
        // })
        // this.props.setCurrentUser("**** 2 ****", user)
        //}
        // catch(e) {
        //   console.log("membership error ", e)
        // }
      })
      .catch((error) => {
        console.error("Error fetch user:", error);
      });
  };

  userIdLinkFormatter = (cell, row) => (
    <Link
      onClick={(evt) => this.setCurrentUser(evt, row)}
      href="/user-overview"
      className="btn btn-link"
      to="/user-overview"
    >
      {cell}
    </Link>
  );

  typeFormatter = (cell) => {
    switch (cell) {
      case 0:
      case "0":
        return <Badge color="primary">student</Badge>;
      case 80:
      case "80":
        return <Badge color="info">instructor as student</Badge>;
      case 89:
      case "89":
        return <Badge color="success">instructor</Badge>;
      case 99:
      case "99":
        return <Badge color="danger">SPL</Badge>;
      default:
        return cell;
    }
  };

  dateFormatter = (cell) => moment(cell).format("MM/DD/YYYY").toString();

  render() {
    console.log('paccissNewUsersList', this.state.paccissNewUsersList)
    return (
      <StyledBootstrapTable
        data={this.state.paccissNewUsersList}
        cellEdit={{ mode: "click", afterSaveCell: this.typeFormatter }}
        options={this.props.options}
        hover={this.props.hover}
        search={this.props.search}
        pagination
      >
        <TableHeaderColumn
          isKey
          dataField="userId"
          dataSort
          dataFormat={this.userIdLinkFormatter}
        >
          ID
        </TableHeaderColumn>
        <TableHeaderColumn dataField="firstName">First Name</TableHeaderColumn>
        <TableHeaderColumn dataField="lastName">Last Name</TableHeaderColumn>
        <TableHeaderColumn dataField="nickName">username</TableHeaderColumn>
        <TableHeaderColumn
          dataField="startDate"
          dataSort
          dataFormat={this.dateFormatter}
        >
          Start Date
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="expirationDate"
          dataSort
          dataFormat={this.dateFormatter}
        >
          Expiration Date
        </TableHeaderColumn>
        <TableHeaderColumn dataField="status">Status</TableHeaderColumn>
        <TableHeaderColumn dataField="type" dataFormat={this.typeFormatter}>
          Type
        </TableHeaderColumn>
      </StyledBootstrapTable>
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      nickName: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired, // PropTypes.instanceOf(Date).isRequired,
      expirationDate: PropTypes.string.isRequired, // PropTypes.instanceOf(Date).isRequired,
      status: PropTypes.number,
      type: PropTypes.number,
    })
  ),
  // cellEdit: PropTypes.Object,
  options: PropTypes.shape({
    toolBar: PropTypes.func,
    clearSearch: PropTypes.bool,
  }).isRequired,
  search: PropTypes.bool,
  hover: PropTypes.bool,
};

UsersTable.defaultProps = {
  users: [],
  search: false,
  hover: false,
};

const mapStateToProps = createStructuredSelector({
  users: (state) => get(state, "users.users"),
  paccissNewUsersList: (state) => paccissNewUsers(state),
  currentUserId: (state) => get(state, "users.currentUserId"),
  currentUser: (state) => get(state, "users.currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchUsersRequest(resolve, reject))
    ),
  setCurrentUser: (user) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.setCurrentUser(user, resolve, reject))
    ),
  fetchUser: (userId) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchUserRequest(userId, resolve, reject))
    ),
  fetchRuns: (userId) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchRunsRequest(userId, resolve, reject))
    ),
  fetchMembershipUser: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchMembershipUserRequest(payload, resolve, reject))
    ),
  checkMbrUserStatus: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.checkMbrUserStatusRequest(payload, resolve, reject))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
