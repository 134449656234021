/* eslint-disable no-unused-vars */
import { createActions, createReducer, Types as ReduxSauceTypes } from "reduxsauce"
import Immutable from "seamless-immutable"

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addAdminUserRequest:["payload", "resolve", "reject"],
  addAdminUserSuccess: ["payload", "resolve", "reject"],
  addAdminUserFailure: ["error"],
  fetchAdminUsersSuccess: ["payload", "resolve", "reject"],
  fetchAdminUsersFailure: ["error"],
  fetchAdminUsersRequest: ["resolve", "reject"],
  defaultHandler: []

})

export const AdminTypes = Types
export default Creators


/* ------------- Initial State ------------- */


export const INITIAL_STATE = Immutable({
  adminUsers: [],
  newAdminUser: {},
  requesting: false,
  error: ""
})

/* ------------- Reducers ------------- */


export const fetchAdminUsersRequest = (state, action) => state.merge({ requesting: true, error: null })

export const fetchAdminUsersSuccess = (state, action) =>
  state.merge({ requesting: false, error: null, adminUsers: action.payload})

export const fetchAdminUsersFailure = (state, { error }) =>
  state.merge({ requesting: false, error })

  export const addAdminUserRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const addAdminUserSuccess = (state, action) =>
    state.merge({ requesting: false, error: null, newAdminUser: action.payload})

  export const addAdminUserFailure = (state, { error }) =>
    state.merge({ requesting: false, error })

  export const defaultHandler = (state, action) =>  state


/* ------------- Hookup Reducers To Types ------------- */

export const HANDLERS = {
  [Types.FETCH_ADMIN_USERS_REQUEST]: fetchAdminUsersRequest,
  [Types.FETCH_ADMIN_USERS_SUCCESS]: fetchAdminUsersSuccess,
  [Types.FETCH_ADMIN_USERS_FAILURE]: fetchAdminUsersFailure,
  [Types.ADD_ADMIN_USER_REQUEST]: addAdminUserRequest,
  [Types.ADD_ADMIN_USER_SUCCESS]: addAdminUserSuccess,
  [Types.ADD_ADMIN_USER_FAILURE]: addAdminUserFailure,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
}

export const reducer =  createReducer(INITIAL_STATE,HANDLERS)
