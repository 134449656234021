import React from "react";
import { withRouter } from "react-router-dom";
import routes from "routes/routes";
import { matchPath } from "react-router-dom";

import "./PageHeader.css";

class PageHeader extends React.Component {
	
	render() {
		if (matchPath(this.props.location.pathname, { path: "/agile-user-overview/:id" })) {
				return (
					<header>
					<h2 className="page-Header">Agile User Overview</h2>
				</header>
				)
		}
		else if (matchPath(this.props.location.pathname, { path: "/organization-overview/:id" })){
			return (
				<header>
				<h2 className="page-Header">Business / University Overview</h2>
			</header>
			)
		}
		return (
			
			<header>
				<h2 className="page-Header">{routes[this.props.location.pathname]}</h2>
			</header>
		);
	}
}

export default withRouter(PageHeader);
