import React, { Component } from "react";
import { Button, Alert } from "reactstrap";
import styled from "styled-components";
import Papa from "papaparse";
// import ClassroomsTable from "../ClassroomsTable/ClassroomsTable";
// import PaccissClassroomsTable from "./PmClassroomsTable";
import PmClassroomsTable from "./PmClassroomsTable";

const UploadHeader = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  > .btn:first-child {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  > .add-users-btn {
    margin-left: auto;
  }

  > * {
    margin: 0 10px 0;
    padding: 0.5rem 0.75rem;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

class ImportPmClassrooms extends Component {
  state = {
    uploadData: [],
    feedback: "",
  };

  handleFileUpload = () => {
    const that = this;
    Papa.parse(this.uploadFileRef.files[0], {
      header: true,
      dynamicTyping: true,
      complete(results) {
        const feedback = {
          text: this.errors
            ? this.errors[0].message
            : "File successfully parsed.",
          color: this.errors ? "danger" : "success",
        };

        that.setState({
          uploadData: results.data,
          feedback,
        });
      },
    });
  };

  addToClassroomsTable = () => {
    // Lori, add this.state.uploadData to the Classrooms here...
  };

  render() {
    return (
      <div>
        <UploadHeader>
          <Button outline color="primary">
            <i className="fa fa-upload" /> Upload CSV file
            <input
              type="file"
              accept=".csv"
              onChange={this.handleFileUpload}
              ref={(node) => {
                this.uploadFileRef = node;
              }}
            />
          </Button>
          {this.state.feedback ? (
            <Alert color={this.state.feedback.color}>
              {this.state.feedback.text}
            </Alert>
          ) : (
            ""
          )}
          {this.state.feedback.color === "success" ? (
            <Button
              className="add-users-btn"
              onClick={this.addToClassroomsTable}
              color="primary"
            >
              Add to Classrooms
            </Button>
          ) : (
            ""
          )}
        </UploadHeader>
        <PmClassroomsTable
          cellEdit={{ mode: "click" }}
          classrooms={this.state.uploadData}
        />
      </div>
    );
  }
}

export default ImportPmClassrooms;
