
export default {
	items: [
		{
			name: "Dashboard",
			url: "/",
			icon: "fa fa-dashboard"
		},
		{
			name: "Users",
			url: "/users-overview",
			icon: "fa fa-users"
		},
		{
			name: "Classrooms",
			url: "/classrooms",
			icon: "fa fa-university"
		},
		{
			name: "Admin Users",
			url: "/adminUsers",
			icon: "fa fa-users"
		},
		{
			name: "Reports",
			url: "/reports",
			icon: "fa fa-wpforms"
		}
	]
};
