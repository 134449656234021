/* eslint  react/prop-types: 0 */

import React from "react";
import {
  Button,
  Popover,
  PopoverBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default class ClassroomPmActionPopover extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      menuIsOpen: true,
    };
    this.actionClicked = this.actionClicked.bind(this);
  }

  actionClicked = (e, action) => {
    e.preventDefault();
    this.props.actionClicked(action, this.props.row, e);
  };
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  render() {
    return (
      <span>
        <Button
          className="fa fa-ellipsis-h"
          color="primary"
          outline
          id={"Popover2-" + this.props.id}
          onClick={this.toggle}
        ></Button>
        <Popover
          placement={this.props.placement}
          isOpen={this.state.popoverOpen}
          target={"Popover2-" + this.props.id}
          toggle={this.toggle}
        >
          <PopoverBody>
            <Dropdown isOpen={true} toggle={this.toggle}>
              <DropdownToggle caret>Choose classroom action</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={(e) => this.actionClicked(e, "inactivate")}
                >
                  Inactivate classroom
                </DropdownItem>
                {this.props.row.classroomStatus === "Registration" ? (
                  <DropdownItem
                    onClick={(e) => this.actionClicked(e, "running")}
                  >
                    Running
                  </DropdownItem>
                ) : (
                  ""
                )}
                {this.props.row.classroomStatus === "Running" ? (
                  <DropdownItem
                    onClick={(e) => this.actionClicked(e, "registration")}
                  >
                    Registration
                  </DropdownItem>
                ) : (
                  ""
                )}
                <DropdownItem
                  onClick={(e) => this.actionClicked(e, "editPrimary")}
                >
                  Edit Primary Instructor
                </DropdownItem>
                {/* <DropdownItem onClick={(e) => this.actionClicked(e, "removeSecondary")}> Edit Secondary Instructor</DropdownItem> */}
                <DropdownItem
                  onClick={(e) => this.actionClicked(e, "addSecondary")}
                >
                  Add Secondary Instructor
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </PopoverBody>
        </Popover>
      </span>
    );
  }
}
