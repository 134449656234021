/* eslint  react/prop-types: 0 */

import React from "react";
import {
	Button,
	Popover,
	PopoverBody,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from "reactstrap";

export default class AgileClassActionPopover extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
            popoverOpen: false,
            menuIsOpen: true,
        };
        this.actionClicked = this.actionClicked.bind(this)
	}

    actionClicked = (e, action) => {
        e.preventDefault();
        this.props.actionClicked(action, this.props.row, e)
    }
	toggle() {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
    }
    

	render() {
		return (
			<span>
                <Button className="fa fa-ellipsis-h" color="primary" outline 
                    id={"Popover-" + this.props.id} 
                    onClick={this.toggle}>
				</Button>
				<Popover
					placement={this.props.placement}
					isOpen={this.state.popoverOpen}
					target={"Popover-" + this.props.id}
					toggle={this.toggle}
				>
					<PopoverBody>
						<Dropdown isOpen={true} toggle={this.toggle}>
							<DropdownToggle caret>Choose classroom action</DropdownToggle>
							<DropdownMenu>
							  <DropdownItem onClick={(e) => this.actionClicked(e, "editClassName")}>Edit Class Name</DropdownItem>
								<DropdownItem onClick={(e) => this.actionClicked(e, "editClassStatus")}>Edit Class Status</DropdownItem>
								<DropdownItem onClick={(e) => this.actionClicked(e, "editClassDates")}>Edit Class Dates</DropdownItem>
								<DropdownItem onClick={(e) => this.actionClicked(e, "editPrimary")}>Edit Primary Instructor</DropdownItem>
								<DropdownItem onClick={(e) => this.actionClicked(e, "addSecondary")}>Add Secondary Instructor</DropdownItem>
	
			{/* <DropdownItem onClick={(e) => this.actionClicked(e, "importUsers")}>Import Users</DropdownItem> */}
							</DropdownMenu>
						</Dropdown>
					</PopoverBody>
				</Popover>
			</span>
		);
	}
}
