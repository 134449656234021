import styled from "styled-components";
import { BootstrapTable } from "react-bootstrap-table";

// react-bootstrap-table styles
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

const StyledBootstrapTable = styled(BootstrapTable)`
	.react-bs-table-bordered {
		border-radius: 0;
		border-bottom: 0;
	}

	.table-bordered thead th,
	.table-bordered thead td {
		font-size: 0.75rem;
	}

	td {
		vertical-align: middle;
	}
`;

export default StyledBootstrapTable;
