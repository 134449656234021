import React, {  } from "react";

import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";

import StyledBootstrapTable from "components/styled/BootstrapTable.js";

 const AdminUsersTable = (props) =>{

	const {adminUsers, hover, search} = props
	return (
		<StyledBootstrapTable
			data={adminUsers}
			// options={options}
			hover={hover}
			search={search}
		>
			<TableHeaderColumn isKey dataField="userId" dataSort>
				User ID
			</TableHeaderColumn>
			<TableHeaderColumn dataField="first" dataSort>
				First Name
			</TableHeaderColumn>
			<TableHeaderColumn dataField="last" dataSort>
				Last Name
			</TableHeaderColumn>
			<TableHeaderColumn dataField="email" dataSort>
			Email
			</TableHeaderColumn>

		</StyledBootstrapTable>
	);



}


AdminUsersTable.propTypes = {
	adminUsers: PropTypes.arrayOf(
		PropTypes.shape({
			userId: PropTypes.number.isRequired,
			first: PropTypes.string.isRequired,
			last: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired
		})
	),
	// options: PropTypes.objectOf(PropTypes.string),
	search: PropTypes.bool,
	hover: PropTypes.bool,
	// storyLinkToUser: PropTypes.func
};

AdminUsersTable.defaultProps = {
	adminUsers: [],
	// options: {},
	search: false,
	hover: false,
	// storyLinkToUser: null,

};

export default AdminUsersTable
