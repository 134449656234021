import React, { Component } from 'react';
import Select from 'react-select';

export default class AddSecondaryInstructor extends Component {


	render() {
		return (
			<div>
				<span>{this.props.currentClassroom.secondaryInstructorName}</span>
				<Select
					id="instructorSelect"
					options={this.props.instructors}
					placeholder="Choose an instructor ..."
					onChange={(selected) => {
						this.props.handleInstructorChange(selected);
					}}
				/>
			</div>
		);
	}
}
