import AuthActions from "./AuthRedux"
import  AdminActions from "./AdminRedux"
import ClassroomActions from "./ClassroomRedux"
import UserActions from "./UserRedux"
import MembershipActions from "./MembershipRedux"
// ADD_IMPORT

export default {
  ...AuthActions,
  ...AdminActions,
  ...ClassroomActions,
  ...UserActions,
  ...MembershipActions
  // ADD_ACTIONS
}
