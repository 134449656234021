import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

export function AdminGet(path) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return axios.get(baseUrl + path, { headers });
}

export function AgilePost(query) {
  const baseUrl = process.env.REACT_APP_AGILE_SERVER_URL;
  return axios.post(baseUrl, query, { headers });
}
