import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { get } from 'lodash';
import Actions from 'redux/Actions';
import { Button, InputGroup, InputGroupAddon, Input, Label } from 'reactstrap';
import styled from 'styled-components';
import moment from 'moment';

const StyledUserActions = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
	grid-gap: 10px;

	.input-group-addon {
		padding: 0;
	}

	.buttons-row {
		display: flex;
		justify-content: space-between;
	}
`;

const UserActions = (props) => {
	const [expirDays, setExpirDays] = useState(0);
	const [expirDate, setExpirDate] = useState('');
	const [email, setEmail] = useState('');

	useEffect(() => {
		if (props.currentUser && props.currentUser.expirationDate) {
			const expirDate = moment(props.currentUser.expirationDate).format('MM/DD/YYYY');
			setEmail(props.currentUser.userEmail);
			setExpirDate(expirDate);
		}
	}, [props.currentUser]);

	const changeExpirDays = (evt) => {
		setExpirDays(evt.target.value);
	};

	const changeExpirDate = (evt) => {
		setExpirDate(evt.target.value);
	};

	const changeEmail = (evt) => {
		setEmail(evt.target.value);
	};

	const resetPassword = () => {
		const payload = {
			username: props.currentMembershipUser.UserName
		};
		props.resetPassword(payload);
	};

	const updateExpirationDateByDays = () => {
		const expirDate = moment(props.currentUser.user.expirationDate);
		const newExpirDt = expirDate.add(expirDays, 'days');
		const payload = {
			userId: props.currentUser.user.userId,
			productId: props.currentUser.licenses[0].productId,
			expirationDate: newExpirDt.utc().format()
		};

		props.updateExpirationDate(payload).then(() => {
			props.fetchUser(payload.userId);
		});
	};

	const updateExpirationDateByDate = () => {
		const payload = {
			userId: props.currentUser.user.userId,
			productId: props.currentUser.licenses[0].productId,
			expirationDate: expirDate
		};

		props.updateExpirationDate(payload).then(() => {
			props.fetchUser(payload.userId);
		});
	};

	const updateEmail = () => {
		const userId = props.currentMembershipUser.UserId;
		const username = props.currentMembershipUser.UserName;
		const newEmail = email;

		const payload1 = {
			username: username,
			newEmail: newEmail
		};

		props.updateEmail(payload1);

		const payload2 = {
			userId: userId,
			email: email
		};
		props.updateUserEmail(payload2);
	};

	return (
		<StyledUserActions>
			<h3>Actions</h3>
			<div className="buttons-row">
				<Button color="primary" onClick={resetPassword}>
					Reset User Password
				</Button>
			</div>
			<div className="buttons-row">
				<Button color="primary" onClick={props.addTestUserAccount}>
					Create Test User Account
				</Button>
			</div>

			<Label>Set new expiration date by day or by adding days</Label>
			<InputGroup>
				<Input
					type="date"
					value={expirDate}
					onChange={changeExpirDate}
					placeholder="mm/dd/yyyy"
				/>
				<InputGroupAddon addonType="prepend">
					<Button color="primary" onClick={updateExpirationDateByDate}>
						Change Expiration Date
					</Button>
				</InputGroupAddon>
			</InputGroup>
			<InputGroup>
				<Input
					type="number"
					value={expirDays}
					onChange={changeExpirDays}
					placeholder="days from today"
					min="0"
				/>
				<InputGroupAddon addonType="prepend">
					<Button color="primary" onClick={updateExpirationDateByDays}>
						Extend License
					</Button>
				</InputGroupAddon>
			</InputGroup>
			<Label>Update email</Label>

			<InputGroup>
				<Input
					type="email"
					id="userEmail"
					value={email || ''}
					onChange={changeEmail}
				/>
				<InputGroupAddon addonType="prepend">
					<Button color="primary" onClick={updateEmail}>
						Update email address
					</Button>
				</InputGroupAddon>
			</InputGroup>
		</StyledUserActions>
	);
};

const mapStateToProps = createStructuredSelector({
	currentMembershipUser: (state) => get(state, 'membership.currentMembershipUser'),
	currentUser: (state) => get(state, 'users.currentUser')
});

const mapDispatchToProps = (dispatch) => ({
	updateUserEmail: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.updateUserEmailRequest(payload, resolve, reject))),
	updateEmail: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.updateEmailRequest(payload, resolve, reject))),
	updateExpirationDate: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.updateExpirationRequest(payload, resolve, reject))),
	resetPassword: (payload) =>
		new Promise((resolve, reject) => dispatch(Actions.adminResetPasswordRequest(payload, resolve, reject))),
	fetchUser: (userId) => new Promise((resolve, reject) => dispatch(Actions.fetchUserRequest(userId, resolve, reject)))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
