import React from "react";
// import styled from "styled-components";
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import PropTypes from "prop-types";
import PaccissNewUserTable from "../UsersTable/PaccissNewUserTable";
import StyledTableHeaderRow from "components/styled/StyledTableHeaderRow";

const PaccissNewUsersListCard = ({
  onRefreshList,
  openModal,
  users,
  onSelect,
}) => {

  const createCustomToolBar = (props) => (
    <Col>
      <StyledTableHeaderRow>
        <Col sm="12" lg="6">
          <Button size="sm" color="primary" onClick={onRefreshList}>
            <i className="fa fa-refresh" />
          </Button>
          <Button
            size="sm"
            outline
            color="primary"
            onClick={() => openModal("create-pacciss-new-user")}
          >
            <i className="fa fa-user" /> Create new pacciss user
          </Button>
        </Col>
        <Col sm="12" lg="6">
          <Row>
            <div className="search">{props.components.searchPanel}</div>
          </Row>
        </Col>
      </StyledTableHeaderRow>
    </Col>
  );

  const tableOptions = {
    toolBar: createCustomToolBar,
    clearSearch: true,
    sizePerPage: 10,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
    ],
  };

  return (
    <Card>
      <CardHeader>Users List</CardHeader>
      <CardBody>
        <PaccissNewUserTable
          options={tableOptions}
          search
          hover
          users={users}
          onSelect={onSelect}
        />
      </CardBody>
    </Card>
  );
};

PaccissNewUsersListCard.propTypes = {
  onRefreshList: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      nickName: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired, // PropTypes.instanceOf(Date).isRequired,
      expirationDate: PropTypes.string.isRequired, // PropTypes.instanceOf(Date).isRequired,
      status: PropTypes.number,
      type: PropTypes.number,
    })
  ),
};

export default PaccissNewUsersListCard;
