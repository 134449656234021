import React, { Fragment } from "react";
import styled from "styled-components";
import {AgilePost} from "../../services/ApiService"
// import FontAwesomeIcon from "@fortawesome/react-fontawesome";
// import {faSpinnerThird} from "@fortawesome/fontawesome-pro-solid";
import Loading from "../../components/Loading";
import ReportTabs from "./components/ReportTabs"


const LoadingContainer = styled.div `
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 200px;
	color: black;

	svg {
		margin-right: 1rem;
	}
`;

export default class Reports extends React.Component{

    state={
		loading:true,
        users:[],
        instructorData:[]
	}
	componentDidMount() {
		Promise.all([
			this.onFetch()
		]).then(
			this.setState({
				loading: false
			}))
	}
	async onFetch(){
		const GET_USERS = { query: `{
			users(where:{role:INSTRUCTOR}){
                id
                email
                firstName
                lastName
    						instructorClasses 
                {
                  classroom {
                    className
                    students
                    {
                      id
                    }
                  }
                  
                  }
  
              }
              }
		` }
		return AgilePost(GET_USERS).then(res =>{
      this.setState({ users: res.data.data.users })
      console.log(res)
		})	   	  
		.catch(function (error) {
		   console.log(error);
		}).then(() => {
            this.getCount(this.state.users)
        })
    }
    getCount = (users) => {
        let value = []
      let students = 0;
        users.forEach((user) => {
            
            user.instructorClasses.forEach(item => {
                students +=  item.classroom.students.length
            })

            var obj = {};
            obj["instructorName"] = user.firstName +" " +user.lastName;
            obj["email"] = user.email ;
            obj["classroomLength"] = user.instructorClasses.length
            obj["studentsLength"] = students ;

            value.push(obj);
           
            students =0
        });
        value.sort((a, b) => parseFloat(b.studentsLength) -  parseFloat(a.studentsLength));
        this.setState({instructorData:value})
    }
    render(){
        return(
            <Fragment>
                {
                    this.state.instructorData.length === 0 ? <LoadingContainer>
                    <Loading />
                </LoadingContainer> : <ReportTabs instructorData={this.state.instructorData} />
                }
                </Fragment>
        )
    }
}
