
import { combineReducers } from "redux";
import { reducer as admin } from "redux/AdminRedux"
import { reducer as auth} from "redux/AuthRedux"
import { reducer as users} from "redux/UserRedux"
import { reducer as membership } from "redux/MembershipRedux"
import { reducer as classroom } from "redux/ClassroomRedux"

/* ------------- Assemble The Reducers ------------- */
export default combineReducers({
  auth,
  admin,
  classroom,
  users,
  membership
})
