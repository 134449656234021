import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Badge, Nav, NavItem, NavLink as RsNavLink } from "reactstrap";
import isExternal from "is-url-external";
import classNames from "classnames";

import nav from "./_nav";
/* These two can be used if needed */
// import SidebarFooter from './components/SidebarFooter';
// import SidebarHeader from './components/SidebarHeader';
import SidebarMinimizer from "./components/SidebarMinimizer";

class Sidebar extends Component {
  handleClick(e) {
    e.preventDefault();
    e.target.parentElement.classList.toggle("open");
  }

  activeRoute(routeName, props) {
    return props.location.pathname.indexOf(routeName) > -1
      ? "nav-item nav-dropdown open"
      : "nav-item nav-dropdown";
  }

  render() {
    const props = this.props;
    const activeRoute = this.activeRoute;
    const handleClick = this.handleClick;

    // badge addon to NavItem
    const badge = (badge) => {
      if (badge) {
        const classes = classNames(badge.class);
        return (
          <Badge className={classes} color={badge.variant}>
            {badge.text}
          </Badge>
        );
      }
    };

    // simple wrapper for nav-title item
    const wrapper = (item) => {
      return item.wrapper && item.wrapper.element
        ? React.createElement(
            item.wrapper.element,
            item.wrapper.attributes,
            item.name
          )
        : item.name;
    };

    // nav list section title
    const title = (title, key) => {
      const classes = classNames("nav-title", title.class);
      return (
        <li key={key} className={classes}>
          {wrapper(title)}{" "}
        </li>
      );
    };

    // nav list divider
    const divider = (divider, key) => <li key={key} className="divider" />;

    // nav item with nav link
    const navItem = (item, key) => {
      const classes = classNames(item.class);
      const variant = classNames(
        "nav-link",
        item.variant ? `nav-link-${item.variant}` : ""
      );
      return (
        <NavItem key={key} className={classes}>
          {isExternal(item.url) ? (
            <RsNavLink
              href={item.url}
              className={variant}
              activeClassName="active"
            >
              <i className={item.icon} />
              <p>{item.name}</p>
              {badge(item.badge)}
            </RsNavLink>
          ) : (
            <NavLink to={item.url} className={variant} activeClassName="active">
              <i className={item.icon} />
              <p>{item.name}</p>
              {badge(item.badge)}
            </NavLink>
          )}
        </NavItem>
      );
    };

    // nav dropdown
    const navDropdown = (item, key) => {
      return (
        <li key={key} className={activeRoute(item.url, props)}>
          <button
            className="nav-link nav-dropdown-toggle"
            onClick={handleClick.bind(this)}
          >
            <i className={item.icon} />
            {item.name}
          </button>
          <ul className="nav-dropdown-items">{navList(item.children)}</ul>
        </li>
      );
    };

    // nav link
    const navLink = (item, idx) =>
      item.title
        ? title(item, idx)
        : item.divider
        ? divider(item, idx)
        : item.children
        ? navDropdown(item, idx)
        : navItem(item, idx);

    // nav list
    const navList = (items) => {
      return items.map((item, index) => navLink(item, index));
    };

    // sidebar-nav root
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <Nav>{navList(nav.items)}</Nav>
        </nav>
        <SidebarMinimizer />
      </div>
    );
  }
}

export default Sidebar;
