/* eslint-disable react/no-unused-state */
import React, { Component, Fragment } from "react";
import { Row, Col, Card,CardHeader, NavItem, NavLink, Nav } from "reactstrap";
import styled from "styled-components";
import InstructorReport from "./InstructorReport"



const StyledNav = styled(Nav)`
  &.nav-pills .nav-link.active,
  &.nav-pills .navbar .active.dropdown-toggle,
  .navbar .nav-pills .active.dropdown-toggle,
  .show > .nav-pills .nav-link,
  .show > .nav-pills .navbar .dropdown-toggle,
  .navbar .show > .nav-pills .dropdown-toggle {
    background-color: var(--primary-color);
  }
`;

const StyledHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .pull-right {
    margin-left: auto;
  }

  .btn {
    margin-top: 0;
  }

  .switch {
    margin-bottom: 0;
  }
`;

class ReportTabs extends Component {
  state = {
    activeReport: "studentsCount",
    collapse: false,
    HistoryCardOpen: false,
    ChartCardOpen: false
  };

  toggleHistoryCard = () => {
    this.setState(prevState => ({
      HistoryCardOpen: !prevState.HistoryCardOpen
    }));
  };

  toggleChartCard = () => {
    this.setState(prevState => ({
      ChartCardOpen: !prevState.ChartCardOpen
    }));
  };

  sprintToggleEvent = activeTeam => {
    this.closeCollapse();
    this.toggleNavLink("burndown");
    this.toggle();
    if (activeTeam.sprints.length !== 0) {
      this.setState({
        activeSprintId: activeTeam.sprints[0].id,
        activeWeek: 0,
        activeSprint: this.props.activeSprint
      });
    }
  };



  toggleNavLink = activeReport => {
    this.setState({ activeReport });
  };

  onEntering() {
    this.setState({ status: "Opening..." });
  }

  onEntered() {
    this.setState({ status: "Opened" });
  }

  onExiting() {
    this.setState({ status: "Closing..." });
  }

  onExited() {
    this.setState({ status: "Closed" });
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  closeCollapse() {
    this.setState(state => ({ collapse: false }));
  }


  render() {
    return (
      <Fragment>
        <Row>
          <Col lg="3">
            <StyledNav vertical pills>
              <NavItem>
                <Card>
                  <StyledHeader className="p-0">
                    <NavLink
                    //   active={this.state.activeReport === "studentsCount"}
                      onClick={event => {
                        this.toggleNavLink("studentsCount");
                        this.toggle();
                        this.closeCollapse();
                        this.setState({
                          activeWeek: this.props.activeWeek,
                          activeSprint: 0
                        });
                      }}
                      href="#"
                    >
                      No. of Students By Instructor
                    </NavLink>
                  </StyledHeader>
                </Card>
              </NavItem>
          
            </StyledNav>
          </Col>
          <Col lg="9">
            {this.state.activeReport === "studentsCount" && (
            <InstructorReport instructorData={this.props.instructorData} />
            )}
      
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default ReportTabs;
