import React, { useEffect, useContext } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonToolbar,
} from "reactstrap";
import { AgileContext } from "../../../../app/store/GlobalState";
import AgileUsersTable from "../UsersTable/AgileUsersTable";
import { AgilePost } from "../../../../services/ApiService";
import { GET_USERS } from "../../../../app/graphql/queries";

const AgileUsersContainer = ({ openModal }) => {
  const [state, dispatch] = useContext(AgileContext);

  useEffect(() => {
    let mounted = true;
    AgilePost(GET_USERS).then((res) => {
      if (mounted) {
        const payload = {
          users: res.data.data.users,
        };
        dispatch({
          type: "RECEIVE_AGILE_USERS",
          payload,
        });
      }
    });
    return () => (mounted = false);
  }, [dispatch]);
  
  const onSelect = (user) => {
    const payload = {
          currentUser: user,
        };
        dispatch({
          type: "SET_CURRENT_USER",
          payload,
        });
  }
  
  return (
    <Card>
      <CardHeader>
        Users List
        <ButtonToolbar style={{ float: "right" }}>
          <Button
            size="sm"
            outline
            color="primary"
            onClick={() => openModal("create-agile-user")}>
            <i className="fa fa-user-plus" />
          </Button>
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              AgilePost(GET_USERS).then((res) => {               
                  const payload = {
                    users: res.data.data.users,
                  };
                  dispatch({
                    type: "RECEIVE_AGILE_USERS",
                    payload,
                  });                
              });
            }}>
            <i className="fa fa-refresh" />
          </Button>
        </ButtonToolbar>
      </CardHeader>
      <CardBody>
        <AgileUsersTable users={state.agileUsers} onSelect={onSelect} />
      </CardBody>
    </Card>
  );
};

export default AgileUsersContainer;
