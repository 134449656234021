import React, { Component, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
} from "reactstrap";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get } from "lodash";
import Actions from "redux/Actions";
// import FontAwesomeIcon from "@fortawesome/react-fontawesome";
// import { faSpinnerThird } from "@fortawesome/fontawesome-pro-solid";
import Loading from "../../components/Loading";
import styled from "styled-components";
import classnames from "classnames";

import UsersListCard from "./components/UsersListCard/UsersListCard";
import PmUsersListCard from "./components/UsersListCard/PmUserlistCard";
import AgileUsersContainer from "./components/UsersListCard/AgileUsersContainer";
import CreateUserForm from "./components/CreateUserForm/CreateUserForm";
import CreatePaccissUserForm from "./components/CreateUserForm/CreatePaccissUserForm";
import CreatePmUserForm from "./components/CreateUserForm/CreatePmUserform";
import CreateUserFormAgile from "./components/CreateUserForm/CreateUserFormAgile";
import ImportUsers from "./components/ImportUsers/ImportUsers";
import ImportPaccissUsers from "./components/ImportUsers/ImportPaccissUser";
import ImportPmUsers from "./components/ImportUsers/ImportPmUser";
import ImportAgileUsers from "./components/ImportUsers/ImportAgileUsers";
import CreatePaccissNewUserForm from "./components/CreateUserForm/CreatePaccissNewUserForm";
import PaccissUsersListCard from "./components/UsersListCard/PaccissUserListCard";
import ImportPaccissNewUser from "./components/ImportUsers/ImportPaccissNewUser";
import PaccissNewUsersListCard from "./components/UsersListCard/PaccissNewUserListCard";

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: black;

  svg {
    margin-right: 1rem;
  }
`;

class Users extends Component {
  state = {
    modal: false,
    agile: false,
    paccissmodal: false,
    paccissnewmodal: false,
    pmmodal: false,
    loading: false,
    userType: "student",

    activeClassDetailTab: "agile",
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  closePopup = () => {
    this.setState({
      paccissnewmodal: false,
    });
  }

  toggleModal = (content) => {
    this.setState({
      modal: !this.state.modal,
      modalContent: content,
    });
  };

  agileToggleModal = (content) => {
    this.setState({
      agilemodal: !this.state.agilemodal,
      agileModalContent: content,
    });
  };

  paccissToggleModal = (content) => {
    this.setState({
      paccissmodal: !this.state.paccissmodal,
      paccissmodalContent: content,
    });
  };

  paccissNewToggleModal = (content) => {
    this.setState({
      paccissnewmodal: !this.state.paccissnewmodal,
      paccissnewmodalContent: content,
    });
  };

  pmToggleModal = (content) => {
    this.setState({
      pmmodal: !this.state.pmmodal,
      pmmodalContent: content,
    });
  };

  toggleTab = (tab) => {
    if (this.state.activeClassDetailTab !== tab) {
      this.setState({
        activeClassDetailTab: tab,
      });
    }
  };

  onRefreshList = () => {
    Promise.all([
      this.setState({ loading: true }),
      this.props.fetchUsers(),
    ]).then(() => {
      this.setState({ loading: false });
    });
  };

  render() {
    return (
      <Fragment>
        <Row>
          <Col>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "agile",
                  })}
                  onClick={() => {
                    this.toggleTab("agile");
                  }}
                >
                  SimAgile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "sim",
                  })}
                  onClick={() => {
                    this.toggleTab("sim");
                  }}
                >
                  SimProject
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "pacciss",
                  })}
                  onClick={() => {
                    this.toggleTab("pacciss");
                  }}
                >
                  Pacciss 20
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "pm",
                  })}
                  onClick={() => {
                    this.toggleTab("pm");
                  }}
                >
                  PM Fundamentals
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeClassDetailTab === "paccissnew",
                  })}
                  onClick={() => {
                    this.toggleTab("paccissnew");
                  }}
                >
                  Pacciss 14
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeClassDetailTab}>
              <TabPane tabId="sim">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        {/* <FontAwesomeIcon spin size="2x" icon={faSpinnerThird} /> Loading */}
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <UsersListCard
                        openModal={this.toggleModal}
                        onRefreshList={this.onRefreshList}
                        users={this.props.users}
                        onSelect={this.setCurrentUser}
                      />
                    )}

                    <Modal
                      isOpen={this.state.modal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size={
                        this.state.modalContent === "import-users" ? "lg" : ""
                      }
                    >
                      <ModalHeader toggle={this.toggleModal}>
                        {this.state.modalContent === "create-users"
                          ? "Create a new user"
                          : "Import users"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.modalContent === "create-users" ? (
                          <CreateUserForm
                            addUser={this.props.addNewUser}
                            userType={this.state.userType}
                            toggleClose={this.paccissNewToggleModal}
                            refreshList={this.onRefreshList}
                          />
                        ) : (
                          <ImportUsers />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={this.toggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="agile">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <AgileUsersContainer
                        openModal={this.agileToggleModal}
                        users={this.state.agileUsers}
                        onSelect={this.setCurrentUser}
                      />
                    )}

                    <Modal
                      isOpen={this.state.agilemodal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size={
                        this.state.agileModalContent === "import-users"
                          ? "lg"
                          : ""
                      }
                    >
                      <ModalHeader toggle={this.agileToggleModal}>
                        {this.state.agileModalContent === "create-agile-user"
                          ? "Create a new Agile user"
                          : "Import users"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.agileModalContent ===
                        "create-agile-user" ? (
                          <CreateUserFormAgile
                            // addUser={this.props.addNewUser}
                            userType={this.state.userType}
                            toggleClose={this.agileToggleModal}
                            refreshList={this.onRefreshList}
                          />
                        ) : (
                          <ImportAgileUsers />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="secondary"
                          onClick={this.agileToggleModal}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
              {/* pacciss tab */}
              <TabPane tabId="pacciss">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        {/* <FontAwesomeIcon spin size="2x" icon={faSpinnerThird} /> Loading */}
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <PaccissUsersListCard
                        openModal={this.paccissToggleModal}
                        onRefreshList={this.onRefreshList}
                        users={this.props.users}
                        onSelect={this.setCurrentUser}
                      />
                    )}

                    <Modal
                      isOpen={this.state.paccissmodal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size={
                        this.state.paccissmodalContent === "import-users"
                          ? "lg"
                          : ""
                      }
                    >
                      <ModalHeader toggle={this.paccissToggleModal}>
                        {this.state.paccissmodalContent ===
                        "create-pacciss-user"
                          ? "Create a pacciss user"
                          : "Import users"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.paccissmodalContent ===
                        "create-pacciss-user" ? (
                          <CreatePaccissUserForm
                            addUser={this.props.addNewUser}
                            userType={this.state.userType}
                            toggleClose={this.paccissToggleModal}
                            refreshList={this.onRefreshList}
                          />
                        ) : (
                          <ImportPaccissUsers />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button
                          color="secondary"
                          onClick={this.paccissToggleModal}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="pm">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        {/* <FontAwesomeIcon spin size="2x" icon={faSpinnerThird} /> Loading */}
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <PmUsersListCard
                        openModal={this.pmToggleModal}
                        onRefreshList={this.onRefreshList}
                        users={this.props.users}
                        onSelect={this.setCurrentUser}
                      />
                    )}

                    <Modal
                      isOpen={this.state.pmmodal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size={
                        this.state.pmmodalContent === "import-users" ? "lg" : ""
                      }
                    >
                      <ModalHeader toggle={this.pmToggleModal}>
                        {this.state.pmmodalContent === "create-pm-user"
                          ? "Create a new user"
                          : "Import users"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.pmmodalContent === "create-pm-user" ? (
                          <CreatePmUserForm
                            addUser={this.props.addNewUser}
                            userType={this.state.userType}
                            toggleClose={this.closeModal}
                            refreshList={this.onRefreshList}
                          />
                        ) : (
                          <ImportPmUsers />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={this.pmToggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="paccissnew">
                <Row>
                  <Col>
                    {this.state.loading ? (
                      <LoadingContainer>
                        {/* <FontAwesomeIcon spin size="2x" icon={faSpinnerThird} /> Loading */}
                        <Loading />
                      </LoadingContainer>
                    ) : (
                      <PaccissNewUsersListCard
                        openModal={this.paccissNewToggleModal}
                        onRefreshList={this.onRefreshList}
                        users={this.props.users}
                        onSelect={this.setCurrentUser}
                      />
                    )}

                    <Modal
                      isOpen={this.state.paccissnewmodal}
                      toggle={this.toggle}
                      className={this.props.className}
                      size={
                        this.state.paccissnewmodalContent === "import-users" ? "lg" : ""
                      }
                    >
                      <ModalHeader toggle={this.paccissNewToggleModal}>
                        {this.state.paccissnewmodalContent === "create-pacciss-new-user"
                          ? "Create a new pacciss user"
                          : "Import users"}
                      </ModalHeader>
                      <ModalBody>
                        {this.state.paccissnewmodalContent === "create-pacciss-new-user" ? (
                          <CreatePaccissNewUserForm
                            addUser={this.props.addNewUser}
                            userType={this.state.userType}
                            toggleClose={this.closeModal}
                            refreshList={this.onRefreshList}
                            closePopup={this.closePopup}
                          />
                        ) : (
                          <ImportPaccissNewUser />
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={this.paccissNewToggleModal}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  users: (state) => get(state, "users.users"),
  currentUser: (state) => get(state, "users.currentUser"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchUsersRequest(resolve, reject))
    ),
  addNewUser: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.addUserRequest(payload, resolve, reject))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
