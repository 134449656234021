import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";

// import ClassroomActionPopover from "../ClassroomActionPopover";
import ClassroomPaccissActionPopover from "./PaccissClassroomPopOver";
import EditPrimaryInstructor from "./EditPrimaryInstructor";
import AddSecondaryInstructor from "./AddSecondaryInstructor";
// import EditSecondaryInstructor from '../EditSecondaryInstructor';

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get } from "lodash";
import Actions from "redux/Actions";
import styled from "styled-components";
import moment from "moment";
import { paccissClassroomOptions, paccissInstructorOptions } from "redux/selectors";
// import FontAwesomeIcon from '@fortawesome/react-fontawesome';
// import { faEllipsisH } from '@fortawesome/fontawesome-pro-solid';
// import { sampleInstructors } from "../../../../utilities/sampleData";

import StyledBootstrapTable from "../../../../components/styled/BootstrapTable.js";

const StyledTableHeaderRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;

  > .col:first-child .btn {
    margin-right: 0px;
  }

  > .col:last-child {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .search {
    margin-top: 0.5rem;
  }
`;
class PaccissClassroomsTable extends Component {
  constructor(props) {
    super(props);

    // need to use state variable for  list because props.classrooms
    // is immutable and you can't sort an immutable list
    this.state = {
      classroomList: [...this.props.classrooms],
      paccissClassroomList: [...this.props.paccissClassroomList],
      instructors: [...this.props.instructors],
      instructorModal: false,
      whichModal: "",
      isOpen: false,
      currentRow: {},
      popoverOpen: false,
      selectedInstructor: {},
      disableSaveButton: true,
      modalAction: "",
      sortName: "classroomId",
      sortOrder: "asc",
      modalMessage: "",
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleInstructorModal = this.toggleInstructorModal.bind(this);
    this.actionItemClicked = this.actionItemClicked.bind(this);
    this.handleEditPrimaryInstructor =
      this.handleEditPrimaryInstructor.bind(this);
    this.handleEditSecondaryInstructor =
      this.handleEditSecondaryInstructor.bind(this);
    this.handleAddSecondaryInstructor =
      this.handleAddSecondaryInstructor.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.paccissClassroomList !== this.props.paccissClassroomList) {
      this.setState({
        paccissClassroomList: [...nextProps.paccissClassroomList],
      });
    }
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sortName,
      sortOrder,
    });
  };

  statusFormatter = (cell) => {
    const cellValue = cell || "";
    switch (cellValue.toLowerCase()) {
      case "running":
        return <Badge color="success">{cellValue}</Badge>;
      case "halted":
        return <Badge color="warning">{cellValue}</Badge>;
      case "inactive":
        return <Badge color="dark">{cellValue}</Badge>;
      case "new":
        return <Badge color="secondary">{cellValue}</Badge>;
      case "registration":
        return <Badge color="info">{cellValue}</Badge>;
      default:
        return cellValue;
    }
  };

  menuFormatter = (cell, row) => {
    if (Number.parseInt(row.classroomId, 10) === Number.parseInt(0, 10)) {
      return <span />;
    }
    return (
      <ClassroomPaccissActionPopover
        row={row}
        id={row.ID}
        placement={"left"}
        actionClicked={this.actionItemClicked}
      />
    );
  };

  primaryInstructorFormatter = (cell) => {
    // If there's no value, return nothing
    if (!cell) {
      return "NO PRIMARY INSTRUCTOR";
    }
    return cell;
    // If our cell instrutor is in our instructor list exactly, return it
    // if (this.props.instructors.includes(cell)) {
    // 	return cell;
    // }

    // Finall, if there's a cell value that does not exist in our instructor list,
    // return error
    //return <Badge color="danger">{cell} value incorrect</Badge>;
  };

  secondaryInstructorFormatter = (cell) => {
    // If there's no value, return nothing
    if (!cell) {
      return "";
    }

    var secondaryString = cell
      .map(function (secondary) {
        return secondary.instructorName;
      })
      .join(", ");

    return secondaryString;
  };
  onRowClick = (row, event) => {
    // event.preventDefault();

    this.setState({
      currentRow: row,
    });
  };

  renderModalHeader = () => {
    if (this.state.modalAction === "editPrimaryPacciss") {
      return (
        <Row>
          <Col sm="8">Change Primary Instructor</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else if (this.state.modalAction === "addSecondaryPacciss") {
      return (
        <Row>
          <Col sm="8">Add Secondary Instructor</Col>
          <Col sm="4">
            <span>{this.state.modalMessage}</span>
          </Col>
        </Row>
      );
    } else {
      return <div>unknown action</div>;
    }
  };

  renderModalBody = () => {
    if (this.state.modalAction === "editPrimaryPacciss") {
      return <span> editing primary ...</span>;
    } else if (this.state.modalAction === "removeSecondary") {
      return (
        <div>
          <span>{this.state.currentRow.secondaryInstructorName}</span>
          <Select
            id="instructorSelectPacciss"
            options={this.props.instructorList}
            placeholder="Choose an instructor ..."
            onChange={(selected) => {
              this.handleInstructorChange(selected);
            }}
          />
        </div>
      );
    } else if (this.state.modalAction === "addSecondaryPacciss") {
      return (
        <div>
          <span>{this.state.currentRow.secondaryInstructorName}</span>
          <Select
            id="instructorSelectPacciss"
            options={this.props.instructorList}
            placeholder="Choose an instructor ..."
            onChange={(selected) => {
              this.handleInstructorChange(selected);
            }}
          />
        </div>
      );
    } else {
      return <div>unknown action</div>;
    }
  };

  toggleInstructorModal = () => {
    this.setState({
      instructorModal: !this.state.instructorModal,
      modalMessage: "",
    });
  };

  toggleDropdown = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  actionItemClicked = (action, row, event) => {
    event.preventDefault();
    if (action === "inactivate") {
      this.props.inactivateClassroom(row.classroomId);
    }
    else if (action === "removeSecondary") {
      const payload = {
        instructorId: row.instructorId,
        classroomId: row.classroomId,
      };
      this.props.removeSecondaryInstructor(payload);
    }
    else if (action === "running") {
      this.props.runningClassroom(row.classroomId);
    }
    else if (action === "registration") {
      this.props.registrationClassroom(row.classroomId);
    } else {
      this.setState({
        instructorModal: true,
        modalAction: action,
      });
    }
  };

  handleEditPrimaryInstructor = (selected) => {
    const payload = {
      instructorId: selected.value,
      classroomId: this.state.currentRow.classroomId,
    };
    this.props.changePrimaryInstructor(payload).then(() => {
      this.setState({
        modalMessage: "Success...",
      });
    });
  };

  handleEditSecondaryInstructor = (selected) => {
    // const payload = {
    // 	instructorId: selected.value,
    // 	classroomId: this.state.currentRow.classroomId
    // }
    // this.props.removeSecondaryInstructor(payload)
  };

  handleAddSecondaryInstructor = (selected) => {
    const payload = {
      instructorId: selected.value,
      classroomId: this.state.currentRow.classroomId,
    };
    this.props.addSecondaryInstructor(payload).then(
      this.setState({
        modalMessage: "Success...",
      })
    );
  };

  dateFormatter = (cell) => {
    if (cell === null) {
      return "";
    }
    return moment(cell).format("MM/DD/YYYY").toString();
  };
  render() {
    //	const { cellEdit, options, hover, search } = this.props;
    const createCustomToolBar = (props) => (
      <Col>
        <StyledTableHeaderRow>
          <Col sm="12" lg="6">
            <Button
              size="sm"
              color="primary"
              onClick={this.props.onRefreshList}
            >
              <i className="fa fa-refresh" />
            </Button>
            <Button
              size="sm"
              outline
              color="primary"
              onClick={() => this.props.openModal("create-pacciss-classroom")}
            >
              <i className="fa fa-graduation-cap" /> Create new classroom
            </Button>

            {/* <Button
						size="sm"
						outline
						color="primary"
						onClick={() => openModal("import-classrooms")}
					>
						<i className="fa fa-upload" /> Import classrooms from CSV
					</Button> */}
          </Col>
          <Col sm="12" lg="6">
            <div className="search">{props.components.searchPanel}</div>
            <div className="search" style={{ color: "maroon" }}>
              <b>Current Sort</b>: column={this.state.sortName}, order=
              {this.state.sortOrder}
            </div>
          </Col>
        </StyledTableHeaderRow>
      </Col>
    );

    const tableOptions = {
      toolBar: createCustomToolBar,
      clearSearch: true,
      sizePerPage: 10,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "30",
          value: 30,
        },
      ],
      onRowClick: (row) => this.onRowClick(row),
      onSortChange: this.onSortChange.bind(this),
    };

    if (
      this.props.classrooms === undefined ||
      this.props.classrooms.length < 1
    ) {
      return <span />;
    }
    return (
      <Card>
        <CardHeader>Classrooms List</CardHeader>
        <CardBody>
          <StyledBootstrapTable
            data={this.state.paccissClassroomList}
            options={tableOptions}
            hover
            search
            striped
            pagination
            key={"tbl"}
          >
            <TableHeaderColumn
              align="center"
              isKey
              dataField="classroomId"
              dataSort
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              width="15%"
              dataField="className"
              tdStyle={{ whiteSpace: "normal" }}
              dataSort
            >
              Class Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="primaryInstructorName"
              tdStyle={{ whiteSpace: "normal" }}
              editable={{
                type: "select",
                options: { values: this.props.instructors },
              }}
              dataSort
              dataFormat={this.primaryInstructorFormatter}
            >
              Prim Instructor
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="secondaries"
              tdStyle={{ whiteSpace: "normal" }}
              editable={{
                type: "select",
                options: { values: this.props.instructors },
              }}
              dataSort
              dataFormat={this.secondaryInstructorFormatter}
            >
              Secondary Instructor
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="startDate"
              dataSort
              dataFormat={this.dateFormatter}
            >
              Start Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="endDate"
              dataSort
              dataFormat={this.dateFormatter}
            >
              End Date
            </TableHeaderColumn>

            <TableHeaderColumn
              dataField="classroomStatus"
              dataFormat={this.statusFormatter}
              dataSort
            >
              Status
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="actions"
              dataFormat={this.menuFormatter}
            >
              Actions
            </TableHeaderColumn>
          </StyledBootstrapTable>
          <Modal
            isOpen={this.state.instructorModal}
            toggle={this.toggleInstructorModal}
            className={this.props.className}
            size="lg"
          >
            <ModalHeader toggle={this.toggleInstructorModal}>
              {this.renderModalHeader()}
            </ModalHeader>
            <ModalBody>
              {this.state.modalAction === "editPrimaryPacciss" ? (
                <EditPrimaryInstructor
                  handleInstructorChange={this.handleEditPrimaryInstructor}
                  instructors={this.props.paccissInstructorList}
                  currentClassroom={this.state.currentRow}
                />
              ) : (
                <AddSecondaryInstructor
                  handleInstructorChange={this.handleAddSecondaryInstructor}
                  instructors={this.props.paccissInstructorList}
                  currentClassroom={this.state.currentRow}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={this.toggleInstructorModal}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </CardBody>
      </Card>
    );
  }
}

PaccissClassroomsTable.propTypes = {
  classrooms: PropTypes.arrayOf(
    PropTypes.shape({
      classroomId: PropTypes.number,
      className: PropTypes.string,
      classroomStatus: PropTypes.string,
      productId: PropTypes.number,
      classroomType: PropTypes.number,
      instructorId: PropTypes.number,
      instructorName: PropTypes.string,
      runsPerStudent: PropTypes.number,
    })
  ),
  cellEdit: PropTypes.shape({
    afterSaveCell: PropTypes.func,
  }),
  options: PropTypes.shape({
    toolBar: PropTypes.func,
    clearSearch: PropTypes.bool,
  }),
  search: PropTypes.bool,
  hover: PropTypes.bool,
  onRefreshList: PropTypes.func,
  openModal: PropTypes.func,
  // search: PropTypes.bool,
  // hover: PropTypes.bool,
  // storyLinkToUser: PropTypes.func
};

PaccissClassroomsTable.defaultProps = {
  options: {},
  search: false,
  hover: false,
};

const mapStateToProps = createStructuredSelector({
  classrooms: (state) => get(state, "classroom.classrooms"),
  instructors: (state) => get(state, "classroom.instructors"),
  paccissClassroomList: (state) => paccissClassroomOptions(state),
  paccissInstructorList: (state) => paccissInstructorOptions(state),
});

const mapDispatchToProps = (dispatch) => ({
  addSecondaryInstructor: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.addSecondaryInstructorRequest(payload, resolve, reject))
    ),
  removeSecondaryInstructor: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(
        Actions.removeSecondaryInstructorRequest(payload, resolve, reject)
      )
    ),
  changePrimaryInstructor: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.changePrimaryInstructorRequest(payload, resolve, reject))
    ),
  inactivateClassroom: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.inactivateClassroomRequest(payload, resolve, reject))
    ),
  runningClassroom: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.runningClassroomRequest(payload, resolve, reject))
    ),
  registrationClassroom: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.registrationClassroomRequest(payload, resolve, reject))
    ),
  fetchClassrooms: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchClassroomsRequest(resolve, reject))
    ),
  fetchInstructors: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchInstructorsRequest(resolve, reject))
    ),
  fetchUser: (userId) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchUserRequest(userId, resolve, reject))
    ),
  fetchRuns: (userId) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchRunsRequest(userId, resolve, reject))
    ),
  fetchMembershipUser: (payload) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchMembershipUserRequest(payload, resolve, reject))
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaccissClassroomsTable);
