import React from "react";
import styled from "styled-components";

const LoadingLayout = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  > span {
    margin-right: 0.5rem;
  }
`;
const LoadingContainer = styled.div `
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 200px;
	color: black;

	svg {
		margin-right: 1rem;
	}
`;

const Loading = ({ text }) => (
  <LoadingContainer>
  <LoadingLayout>
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> {text}
  </LoadingLayout>
  </LoadingContainer>
);


export default Loading;
