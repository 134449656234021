import React from "react";

const Footer = () => (
	<footer className="app-footer">
		<span>Admin Panel</span>
		<span className="float-right">
			&copy; {new Date().getFullYear()} Simulation Powered Learning.
		</span>
	</footer>
);

export default Footer;
