import React, { Component } from "react";
import { NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from "reactstrap";

class Header extends Component {
	sidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-hidden");
	}

	sidebarMinimize(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-minimized");
	}

	mobileSidebarToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("sidebar-mobile-show");
	}

	asideToggle(e) {
		e.preventDefault();
		document.body.classList.toggle("aside-menu-hidden");
	}

	render() {
		return (
			<header className="app-header navbar">
				<NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
					&#9776;
				</NavbarToggler>
				<NavbarBrand href="#" />
				<NavbarToggler
					className="d-md-down-none mr-auto"
					onClick={this.sidebarToggle}
				>
					&#9776;
				</NavbarToggler>

				<Nav className="ml-auto mr-3" navbar>
					<NavItem className="d-md-down-none">
						<NavLink onClick={this.props.signout} tag="button">
							<i className="fa fa-lock" /> Logout
						</NavLink>
					</NavItem>
				</Nav>
			</header>
		);
	}
}

export default Header;
