/* eslint-disable no-unused-vars */
import { createActions, createReducer, Types as ReduxSauceTypes } from "reduxsauce"
import Immutable from "seamless-immutable"

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addClassroomRequest:["payload", "resolve", "reject"],
  addClassroomSuccess: ["payload", "resolve", "reject"],
  addClassroomFailure: ["error"],
  inactivateClassroomRequest:["payload", "resolve", "reject"],
  inactivateClassroomSuccess: ["payload", "resolve", "reject"],
  inactivateClassroomFailure: ["error"],
  runningClassroomRequest:["payload", "resolve", "reject"],
  runningClassroomSuccess: ["payload", "resolve", "reject"],
  runningClassroomFailure: ["error"],
  registrationClassroomRequest:["payload", "resolve", "reject"],
  registrationClassroomSuccess: ["payload", "resolve", "reject"],
  registrationClassroomFailure: ["error"],
  fetchClassroomsSuccess: ["payload", "resolve", "reject"],
  fetchClassroomsFailure: ["error"],
  fetchClassroomsRequest: ["resolve", "reject"],
  fetchInstructorsSuccess: ["payload", "resolve", "reject"],
  fetchInstructorsFailure: ["error"],
  fetchInstructorsRequest: ["resolve", "reject"],
  addSecondaryInstructorRequest:["payload", "resolve", "reject"],
  addSecondaryInstructorSuccess: ["payload", "resolve", "reject"],
  addSecondaryInstructorFailure: ["error"],
  removeSecondaryInstructorRequest:["payload", "resolve", "reject"],
  removeSecondaryInstructorSuccess: ["payload", "resolve", "reject"],
  removeSecondaryInstructorFailure: ["error"],
  changePrimaryInstructorRequest:["payload", "resolve", "reject"],
  changePrimaryInstructorSuccess: ["payload", "resolve", "reject"],
  changePrimaryInstructorFailure: ["error"],
  defaultHandler: []

})

export const ClassroomTypes = Types
export default Creators


/* ------------- Initial State ------------- */


export const INITIAL_STATE = Immutable({
  classrooms: [],
  instructors: [],
  currentInstructor: {},
  newClassroom: {},
  requesting: false,
  error: ""
})

/* ------------- Reducers ------------- */


export const fetchClassroomsRequest = (state, action) => state.merge({ requesting: true, error: null })

export const fetchClassroomsSuccess = (state, action) =>
  state.merge({ requesting: false, error: null, classrooms: action.payload})

export const fetchClassroomsFailure = (state, { error }) =>
  state.merge({ requesting: false, error })

  export const fetchInstructorsRequest = (state, action) => state.merge({ requesting: true, error: null })

  export const fetchInstructorsSuccess = (state, action) => {
    return state.merge({ requesting: false, error: null, instructors: action.payload})
    }

  export const fetchInstructorsFailure = (state, { error }) =>
    state.merge({ requesting: false, error })

  export const addClassroomRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const addClassroomSuccess = (state, action) =>
    state.merge({ requesting: false, error: null, newClassroom: action.payload})

  export const addClassroomFailure = (state, { error }) =>
    state.merge({ requesting: false, error })

    export const inactivateClassroomRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const inactivateClassroomSuccess = (state, action) =>
    state.merge({ requesting: false, error: null, newClassroom: action.payload})

  export const inactivateClassroomFailure = (state, { error }) =>
    state.merge({ requesting: false, error })

    export const runningClassroomRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const runningClassroomSuccess = (state, action) =>
    state.merge({ requesting: false, error: null, newClassroom: action.payload})

  export const runningClassroomFailure = (state, { error }) =>
    state.merge({ requesting: false, error })
    export const registrationClassroomRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const registrationClassroomSuccess = (state, action) =>
    state.merge({ requesting: false, error: null, newClassroom: action.payload})

  export const registrationClassroomFailure = (state, { error }) =>
    state.merge({ requesting: false, error })


    export const addSecondaryInstructorRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const addSecondaryInstructorSuccess = (state, action) =>
    state.merge({ requesting: false, error: null})

  export const addSecondaryInstructorFailure = (state, { error }) =>
    state.merge({ requesting: false, error })

    export const removeSecondaryInstructorRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const removeSecondaryInstructorSuccess = (state, action) =>
    state.merge({ requesting: false, error: null})

  export const removeSecondaryInstructorFailure = (state, { error }) =>
    state.merge({ requesting: false, error })


    export const changePrimaryInstructorRequest = (state, action) =>
    state.merge({ requesting: true, error: null })

  export const changePrimaryInstructorSuccess = (state, action) =>
    state.merge({ requesting: false, error: null})

  export const changePrimaryInstructorFailure = (state, { error }) =>
    state.merge({ requesting: false, error })

  export const defaultHandler = (state, action) =>  state


/* ------------- Hookup Reducers To Types ------------- */

export const HANDLERS = {
  [Types.FETCH_CLASSROOMS_REQUEST]: fetchClassroomsRequest,
  [Types.FETCH_CLASSROOMS_SUCCESS]: fetchClassroomsSuccess,
  [Types.FETCH_CLASSROOMS_FAILURE]: fetchClassroomsFailure,
  [Types.FETCH_INSTRUCTORS_REQUEST]: fetchInstructorsRequest,
  [Types.FETCH_INSTRUCTORS_SUCCESS]: fetchInstructorsSuccess,
  [Types.FETCH_INSTRUCTORS_FAILURE]: fetchInstructorsFailure,
  [Types.ADD_CLASSROOM_REQUEST]: addClassroomRequest,
  [Types.ADD_CLASSROOM_SUCCESS]: addClassroomSuccess,
  [Types.ADD_CLASSROOM_FAILURE]: addClassroomFailure,
  [Types.INACTIVATE_CLASSROOM_REQUEST]: inactivateClassroomRequest,
  [Types.INACTIVATE_CLASSROOM_SUCCESS]: inactivateClassroomSuccess,
  [Types.INACTIVATE_CLASSROOM_FAILURE]: inactivateClassroomFailure,
  [Types.RUNNING_CLASSROOM_REQUEST]: runningClassroomRequest,
  [Types.RUNNING_CLASSROOM_SUCCESS]: runningClassroomSuccess,
  [Types.RUNNING_CLASSROOM_FAILURE]: runningClassroomFailure,
  [Types.REGISTRATION_CLASSROOM_REQUEST]: registrationClassroomRequest,
  [Types.REGISTRATION_CLASSROOM_SUCCESS]: registrationClassroomSuccess,
  [Types.REGISTRATION_CLASSROOM_FAILURE]: registrationClassroomFailure,
  [Types.ADD_SECONDARY_INSTRUCTOR_REQUEST]: addSecondaryInstructorRequest,
  [Types.ADD_SECONDARY_INSTRUCTOR_SUCCESS]: addSecondaryInstructorSuccess,
  [Types.ADD_SECONDARY_INSTRUCTOR_FAILURE]: addSecondaryInstructorFailure,
  [Types.REMOVE_SECONDARY_INSTRUCTOR_REQUEST]: removeSecondaryInstructorRequest,
  [Types.REMOVE_SECONDARY_INSTRUCTOR_SUCCESS]: removeSecondaryInstructorSuccess,
  [Types.REMOVE_SECONDARY_INSTRUCTOR_FAILURE]: removeSecondaryInstructorFailure,

  [Types.CHANGE_PRIMARY_INSTRUCTOR_REQUEST]: changePrimaryInstructorRequest,
  [Types.CHANGE_PRIMARY_INSTRUCTOR_SUCCESS]: changePrimaryInstructorSuccess,
  [Types.CHANGE_PRIMARY_INSTRUCTOR_FAILURE]: changePrimaryInstructorFailure,

  [ReduxSauceTypes.DEFAULT]: defaultHandler,
}

export const reducer =  createReducer(INITIAL_STATE,HANDLERS)
