/* eslint-disable import/prefer-default-export */

export const parseError = (response) => {
if (response) {
  // let error = get(response, "data.meta.message")
  // if (response.problem === "NETWORK_ERROR" || response.problem === "SERVER_ERROR")
  //   error = "Backend server is down."
//  return error
 return response
}
  return "No response"

}
