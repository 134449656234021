import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { get } from "lodash";
import Actions from "redux/Actions";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import { AgilePost } from "../../services/ApiService";
import AdminUsersTable from "./components/AdminUsersTable/";

class AdminUsers extends Component {
  state = {
    activeClassDetailTab: "sim",
    adminUsers: [],
  };
  toggleTab = (tab) => {
    if (this.state.activeClassDetailTab !== tab) {
      this.setState({
        activeClassDetailTab: tab,
      });
    }
  };

  componentDidMount = () => {
    this.props.fetchAdminUsers();
    this.onFetch();
  };

  async onFetch() {
    const GET_ADMIN_USERS = {
      query: `
      {
        users
        {      
          id            
          firstName        
          lastName
          email
        }
    } 
      `,
    };
    AgilePost(GET_ADMIN_USERS)
      .then((res) => {
        this.setState({ adminUsers: res.data.data.users });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const tableOptions = {
      clearSearch: true,
    };
    return (
      <Fragment>
        <Row>
          <Col>
            <Card>
              <CardHeader>Admin Users List - <strong>fake data - not implemented</strong></CardHeader>
              <CardBody>
                <AdminUsersTable
                  adminUsers={this.props.adminUsers}
                  options={tableOptions}
                  search
                  hover
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

AdminUsers.defaultProps = {
  adminUsers: [],
  // options: {},
};

AdminUsers.propTypes = {
  adminUsers: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      first: PropTypes.string.isRequired,
      last: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ),
};

const mapStateToProps = createStructuredSelector({
  adminUsers: (state) => get(state, "admin.adminUsers"),
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdminUsers: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchAdminUsersRequest(resolve, reject))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
