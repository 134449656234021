import React, { Component } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
} from "reactstrap";
import Actions from "redux/Actions";
import history from "app/store/history";
import "./Login.css";

import logo from "../../static/images/logo-spl--full.png";

class Login extends Component {
  state = {
    loginError: "",
  };

  loginUser = () => {
    this.props
       .login(this.emailField.value, this.passwordField.value)
      .then(() => {
        if (this.props.authenticated) {
          this.props.fetchUsers();
          const url = "/";
          history.push(url);
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loginError: error,
        });
      });
  };

  onKeyPress = (e) => {
    if (e.which === 13) {
      this.loginUser();
    }
  };

  render() {
    return (
      <div className="app route-Login flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="mb-0">
                <Card
                  className="py-4 d-md-down-none"
                  style={{ width: `${44}%` }}>
                  <CardBody className="card-body text-center">
                    <div>
                      <img src={logo} alt="SPL Logo" className="logo" />
                      <h4>Admin Panel</h4>
                    </div>
                  </CardBody>
                </Card>
                <Card className="p-4">
                  <CardBody className="card-body">
                    <h1>Login</h1>
                    <p className="text-muted">Sign In to your account</p>
                    <p style={{ color: "red" }}>{this.state.loginError}</p>
                    <Form>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <i className="fa fa-user" />
                        </InputGroupAddon>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Username"
                          innerRef={(ef) => (this.emailField = ef)}
                        />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <i className="fa fa-lock" />
                        </InputGroupAddon>
                        <Input
                          onKeyPress={this.onKeyPress}
                          type="password"
                          placeholder="Password"
                          name="password"
                          innerRef={(pf) => (this.passwordField = pf)}
                        />
                      </InputGroup>
                    </Form>
                    <Row>
                      <Col xs="6">
                        <Button
                          className="btn btn-primary px-4"
                          onClick={this.loginUser}>
                          Login
                        </Button>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button color="link" className="px-0">
                          Forgot password?
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (email, password) =>
    new Promise((resolve, reject) =>
      dispatch(Actions.loginRequest(email, password, resolve, reject))
    ),
  fetchUsers: () =>
    new Promise((resolve, reject) =>
      dispatch(Actions.fetchUsersRequest(resolve, reject))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

// export default Login;
