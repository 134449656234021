import React, { Component } from "react";
import Select from "react-select";
import { AgilePost } from "../../../../services/ApiService"

export default class AddSecondaryInstructorAgile extends Component {

	addSecondaryInstructor = (selected) => {
		const ADD_SECONDARY_INSTRUCTOR = {
				query: `mutation updateClassroom(
						$id: ID!
						$secondaryInstructorId: ID!
					) {
						updateClassroom(
								id:$id,
								secondaryInstructorId:$secondaryInstructorId
						) {
							id
						}
					}`,
				variables: {
					id: this.props.currentClassroom.id,
						secondaryInstructorId: selected.value
				}
		}

		AgilePost(ADD_SECONDARY_INSTRUCTOR)
};

	render() {
		return (
			<div>
				<Select
					id="instructorSelect"
					options={this.props.instructors}
					placeholder="Choose an instructor ..."
					onChange={(selected) => {
						this.addSecondaryInstructor(selected);
					}}
				/>
			</div>
		);
	}
}
