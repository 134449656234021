import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Alert,
} from "reactstrap";
import styled from "styled-components";
import moment from "moment";
import { formatDate, parseDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";

const StyledUserActions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  grid-gap: 10px;

  .input-group-addon {
    padding: 0;
  }

  .buttons-row {
    display: flex;
    justify-content: space-between;
  }
`;

function UserActions({ user, updateLicense, updateUser, resendVerificationEmail }) {
  const [expirDays, setExpirDays] = useState(0);
  const [expirDate, setExpirDate] = useState(moment().format());
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const emailRef = useRef(null);

  useEffect(() => {
    if (user && user.licenses && user.licenses.length > 0) {
      setExpirDate(user.licenses[0].expirationDate);
    }
  }, [user]);

  useEffect(() => {
    const timeout = setTimeout(() => setMessage(""), 10000);
    return () => clearTimeout(timeout);
  }, [message]);

  const changeExpirDays = (evt) => {
    setExpirDays(evt.target.value);
  };

  const changeExpirDate = (date) => {
    setExpirDate(date);
  };

  const changeEmail = (evt) => {
    setEmail(evt.target.value);
  };

  const updateExpirationDateByDays = async () => {
    setMessage("Updating...");

    const newExpirDt = moment(user.licenses[0].licenseExpirationDate).add(expirDays, "days");
    const payload = {
      id: user.licenses[0].id,
      expirationDate: moment(newExpirDt).toDate(),
    };

    await updateLicense(payload);
    setExpirDays(0);
    setMessage("Done");
  };

  const updateExpirationDateByDate = async () => {
    setMessage("Updating...");

    const payload = {
      userId: user.id,
      id: user.licenses[0].id,
      expirationDate: moment(expirDate, "YYYY-MM-DD"),
    };

    await updateLicense(payload);
    setMessage("Done");
  };

  const updateEmail = async () => {
    const id = user.id;
    setMessage("Updating...");
    const payload = {
      id: id,
      email: email,
    };

    await updateUser(payload);
    setEmail("");
    setMessage("Done");
  };

  const resendEmail = async (evt) => {
    evt.preventDefault();
    setMessage("Sending...");
    const response = await resendVerificationEmail({ id: user.id });

    if (response && response.data && response.data.data && response.data.data.resendVerificationEmail && response.data.data.resendVerificationEmail.id) {
      setMessage("Email Sent");
    } else if (response && response.data && response.data.errors && response.data.errors.length > 0) {
      setMessage(response.data.errors[0].extensions.message);
    }
  };

  return (
    <Card>
      <CardHeader>
        <strong>Actions</strong>
      </CardHeader>
      <CardBody>
        {message !== "" && (
          <Alert color="info">
            <b></b>
            {message}
          </Alert>
        )}
        <StyledUserActions>
          <Row>
            <Col></Col>
          </Row>
          <InputGroup>
            <Button color="primary" onClick={resendEmail}>
              Resend Verification Email
            </Button>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="expirDays">
              <strong>Add days:</strong>
            </Label>

            <Input
              id="expirDays"
              type="number"
              value={expirDays}
              onChange={changeExpirDays}
              placeholder="days from today"
            />
            <InputGroupAddon addonType="prepend">
              <Button color="primary" onClick={updateExpirationDateByDays}>
                Extend License
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <InputGroup>
            <Label htmlFor="expirDate">
              <strong>New Expiration:</strong>
            </Label>

            <DayPickerInput
              formatDate={formatDate}
              style={{ marginLeft: "5px" }}
              parseDate={parseDate}
              onDayChange={changeExpirDate}
              placeholder={`${formatDate(expirDate)}`}
            />
            <InputGroupAddon addonType="prepend">
              <Button color="primary" onClick={updateExpirationDateByDate}>
                Change Expiration Date
              </Button>
            </InputGroupAddon>
          </InputGroup>

          <InputGroup>
            <Label htmlFor="userEmail">
              <strong>New email:</strong>
            </Label>
            <Input
              type="email"
              id="userEmail"
              style={{ marginLeft: "5px" }}
              ref={emailRef}
              value={email}
              onChange={changeEmail}
            />
            <InputGroupAddon addonType="prepend">
              <Button color="primary" onClick={updateEmail}>
                Update email address
              </Button>
            </InputGroupAddon>
          </InputGroup>

          {/* <Button color="danger">Close Account</Button> */}
        </StyledUserActions>
      </CardBody>
    </Card>
  );
}

export default UserActions;