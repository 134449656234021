import React from "react";
import ReactDOM from "react-dom";
import Root from "app/Root";
import configureStore from "app/store";
import history from "app/store/history";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const store = configureStore();

ReactDOM.render(
	<Root store={store} history={history} />,
	document.getElementById("root")
);

if (module.hot) {
	module.hot.accept('app/Root', () => {
	  const NextApp = require('app/Root').default;
	  ReactDOM.render(NextApp);
	});
  }
