import React from "react";
import { Card, CardBody, Badge } from "reactstrap";
import styled from "styled-components";
import moment from "moment"

const AgileUserCard = (props) => {

  const typeFormatter = cell => {
    switch (cell) {
      case 0:
      case "0":
        return <Badge color="primary">student</Badge>;
      case 80:
      case "80":
        return <Badge color="info">instructor as student</Badge>;
      case 89:
      case "89":
        return <Badge color="success">instructor</Badge>;
      case 99:
      case "99":
        return <Badge color="danger">SPL</Badge>;
      default:
        return cell;
    }
  };

  const formatClass = () => {
    if (props.user !== null && props.user !== undefined && props.user.currentClassroom !== null && props.user.currentClassroom !== undefined) {

      if (props.user.currentClassroom.id === undefined) {
        return "Class not set"
      } else {
        return ` ${props.user.currentClassroom.className}`
      }
    } else {
      return "Student / Classroom information not available - student may not be registered or may be instructor"

    }
  }
 const formatInstructor = () => {
    if (props.user !== null && props.user !== undefined && props.user.currentClassroom !== null && props.user.currentClassroom !== undefined) {
      // find the primary instructor
      if (props.user.currentClassroom.instructors !== null && props.user.currentClassroom.instructors.length > 0) {
        const primaryInstructor = props.user.currentClassroom.instructors.find(i => i.type === "PRIMARY")
        if (primaryInstructor && primaryInstructor !== null) {
          // return `${primaryInstructor.user.firstName} ${primaryInstructor.user.lastName}`
          return `${primaryInstructor.user ? primaryInstructor.user.firstName : ''} ${primaryInstructor.user ? primaryInstructor.user.lastName : ''}`
        }
      }
    }   
  return "Instructor not set"   
  }
	const formatDate = cell => moment(cell).format("MM/DD/YYYY");

 
    return (
      <Card>
        <CardBody>
          <h3>
            {
              props.user.firstName
                ? (`${props.user.firstName} ${props.user.lastName}`)
                : (<Badge color="danger">Name incomplete!</Badge>)
            }
          </h3>
          <DetailsList>
            <li>
              <h4>User ID:</h4>
              <p>{props.user.id}</p>
            </li>
            <li>
              <h4>Username:</h4>
              <p>
                {
                  props.user.firstName + " " + props.user.lastName || (<Badge color="warning">
                    nickName empty</Badge>)
                }
              </p>
            </li>
            <li>



              <h4>Type:</h4>
              <p>
                {props.user.role && typeFormatter(props.user.role)}

              </p>
              <p>{!props.user.role && <Badge color="warning">type empty</Badge>}

              </p>
            </li>
            <li>
              <h4>Email:</h4>
              <p>
                {
                  props.user.email || (<Badge color="warning">
                    userEmail empty</Badge>)
                }
              </p>
            </li>
                 <li>
              <h4>Status:</h4>
              <p>
                {
                  formatEnumValue(props.user.welcomeStatus) || (<Badge color="warning">
                    welcomeStatus empty</Badge>)
                }
              </p>
            </li>

            <li>
              <h4>Class:</h4>
              <p>
                {formatClass()}
              </p>
            </li>
            <li>
              <h4>Instructor:</h4>
              <p>
                {formatInstructor()}
              </p>
            </li>
              <li>
              <h4>Start Date:</h4>
              <p>{
                props.user.licenses && props.user.licenses.length > 0 &&
                  props.user.licenses[0] && props.user.licenses[0].id
                  ? formatDate(props.user.licenses[0].registrationDate)
                  : "🤷"
              }</p>
            </li>
            <li>
              <h4>Expiration Date:</h4>
              <p>{
                props.user.licenses && props.user.licenses.length > 0 &&
                  props.user.licenses[0] && props.user.licenses[0].id
                  ? formatDate(props.user.licenses[0].expirationDate)
                  : "🤷"
              }</p>
            </li>
            <li>
              <h4>License Acceptance Date:</h4>
              <p>{
                props.user.licenses && props.user.licenses.length > 0 &&
                  props.user.licenses[0] && props.user.licenses[0].id
                  ? formatDate(props.user.licenses[0].acceptanceDate)
                  : "🤷"
              }</p>
            </li>


          </DetailsList>

        </CardBody>
      </Card>

    )
  };


export default AgileUserCard;
const DetailsList = styled.ul`
	list-style: none;
	padding-left: 0;
	column-count: 2;

	li {
		display: flex;
		align-items: center;
		font-size: 0.75rem;
		margin-bottom: 0.5rem;
	}

	h4 {
		font-size: 1em;
		margin-bottom: 0;
		line-height: 1rem;
		margin-right: 0.5rem;
		font-weight: 600;
	}

	p {
		margin-bottom: 0;
	}
`;

export const formatEnumValue = value => {
  if (value === undefined || value === null) return "";

  let enumValues = [];

  // assuming that words in enum entry is formed by _ character
  if (value.indexOf("_")) {
    const words = value.split("_");
    enumValues = enumValues.concat(words);
  } else {
    enumValues.push(value);
  }

  // convert every word to title case
  const titleCaseWords = enumValues.map(v => {
    return toTitleCase(v);
  });

  const formattedString = titleCaseWords.join(" ");
  return formattedString;
};
export const toTitleCase = str => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
