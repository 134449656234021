import { createSelector } from "reselect";

const getClassrooms = (state) => state.classroom.classrooms;
const getInstructors = (state) => state.classroom.instructors;
const getUsers = (state) => state.users.users;

export const instructorOptions = createSelector(
  [getInstructors],
  (instructors) => {
    let list = [];
    for (var i = 0, l = instructors.length; i < l; i++) {
      list.push({
        value: instructors[i].id,
        label: instructors[i].instructorName,
      });
    }
    return list;
  }
);

export const instructorUsers = createSelector([getUsers], (users) => {
  let list = [];
  for (var i = 0, l = users.length; i < l; i++) {
    if (users[i].type === 89) {
      list.push(users[i]);
    }
  }
  return list;
});

export const instructorStudentUsers = createSelector([getUsers], (users) => {
  let list = [];
  for (var i = 0, l = users.length; i < l; i++) {
    if (users[i].type === 80) {
      list.push(users[i]);
    }
  }
  return list;
});
export const paccissUsers = createSelector([getUsers], (users) => {
  let list = [];
  for (var i = 0, l = users.length; i < l; i++) {
    if (
      users[i].productId === 50 ||
      users[i].productId === null ||
      users[i].productId === undefined
    ) {
      list.push(users[i]);
    }
  }
  return list;
});

export const paccissNewUsers = createSelector([getUsers], (users) => {
  let list = [];
  for (var i = 0, l = users.length; i < l; i++) {
    if (
      users[i].productId === 51 ||
      users[i].productId === null ||
      users[i].productId === undefined
    ) {
      list.push(users[i]);
    }
  }
  return list;
});

export const pmUsers = createSelector([getUsers], (users) => {
  let list = [];
  for (var i = 0, l = users.length; i < l; i++) {
    if (
      users[i].productId === 43 ||
      users[i].productId === null ||
      users[i].productId === undefined
    ) {
      list.push(users[i]);
    }
  }
  return list;
});

export const paccissClassroomOptions = createSelector(
  [getClassrooms],
  (classrooms) => {
    let list = [];
    for (var i = 0, l = classrooms.length; i < l; i++) {
      if (classrooms[i].productId === 50) {
        list.push(
          // {
          //   value: classrooms[i].id,
          //   label: classrooms[i].instructorName,
          // },
          classrooms[i]
        );
      }
    }
    return list;
  }
);

export const pmClassroomOptions = createSelector(
  [getClassrooms],
  (classrooms) => {
    let list = [];
    for (var i = 0, l = classrooms.length; i < l; i++) {
      if (classrooms[i].productId === 43) {
        list.push(
          // {
          //   value: classrooms[i].id,
          //   label: classrooms[i].instructorName,
          // }
          classrooms[i]
        );
      }
    }
    return list;
  }
);

export const paccissNewClassroomOptions = createSelector(
  [getClassrooms],
  (classrooms) => {
    let list = [];
    for (var i = 0, l = classrooms.length; i < l; i++) {
      if (classrooms[i].productId === 51) {
        list.push(
          // {
          //   value: classrooms[i].id,
          //   label: classrooms[i].instructorName,
          // },
          classrooms[i]
        );
      }
    }
    return list;
  }
);

export const simUsers = createSelector([getUsers], (users) => {
  let list = [];
  for (var i = 0, l = users.length; i < l; i++) {
    if (
      users[i].productId === 38 ||
      users[i].productId === null ||
      users[i].productId === undefined
    ) {
      list.push(users[i]);
    }
  }
  return list;
});
export const simInstructorOptions = createSelector(
  [getInstructors],
  (instructors) => {
    let list = [];
    for (var i = 0, l = instructors.length; i < l; i++) {
      if (
        instructors[i].productId === 38 ||
        instructors[i].productId === null ||
        instructors[i].productId === undefined
      ) {
        list.push({
          value: instructors[i].id,
          label: instructors[i].instructorName,
        });
      }
    }
    return list;
  }
);
export const paccissInstructorOptions = createSelector(
  [getInstructors],
  (instructors) => {
    let list = [];
    for (var i = 0, l = instructors.length; i < l; i++) {
      if (
        instructors[i].productId === 50 ||
        instructors[i].productId === null ||
        instructors[i].productId === undefined
      ) {
        list.push({
          value: instructors[i].id,
          label: instructors[i].instructorName,
        });
      }
    }
    return list;
  }
);
export const pmInstructorOptions = createSelector(
  [getInstructors],
  (instructors) => {
    let list = [];
    for (var i = 0, l = instructors.length; i < l; i++) {
      if (
        instructors[i].productId === 43 ||
        instructors[i].productId === null ||
        instructors[i].productId === undefined
      ) {
        list.push({
          value: instructors[i].id,
          label: instructors[i].instructorName,
        });
      }
    }
    return list;
  }
);
export const paccissNewInstructorOptions = createSelector(
  [getInstructors],
  (instructors) => {
    let list = [];
    for (var i = 0, l = instructors.length; i < l; i++) {
      if (
        instructors[i].productId === 51 ||
        instructors[i].productId === null ||
        instructors[i].productId === undefined
      ) {
        list.push({
          value: instructors[i].id,
          label: instructors[i].instructorName,
        });
      }
    }
    return list;
  }
);
export const simClassroomOptions = createSelector(
  [getClassrooms],
  (classrooms) => {
    let list = [];
    for (var i = 0, l = classrooms.length; i < l; i++) {
      if (classrooms[i].productId === 38) {
        list.push(
          // {
          //   value: classrooms[i].id,
          //   label: classrooms[i].instructorName,
          // }
          classrooms[i]
        );
      }
    }
    return list;
  }
);
