/* eslint-disable no-unused-vars */
import {createActions, createReducer, Types as ReduxSauceTypes} from "reduxsauce"
import Immutable from "seamless-immutable"

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({

  fetchUsersSuccess: [
    "payload", "resolve", "reject"
  ],
  fetchUsersFailure: ["error"],

  fetchUsersRequest: [
    "resolve", "reject"
  ],
  fetchUserSuccess: [
    "payload", "resolve", "reject"
  ],
  fetchUserFailure: ["error"],
  fetchUserRequest: [
    "payload", "resolve", "reject"
  ],

  updateExpirationRequest: [
    "payload", "resolve", "reject"
  ],
  updateExpirationSuccess: [
    "payload", "resolve", "reject"
  ],
  updateExpirationFailure: ["error"],

  updateUserEmailRequest: [
    "payload", "resolve", "reject"
  ],
  updateUserEmailSuccess: [
    "payload", "resolve", "reject"
  ],
  updateUserEmailFailure: ["error"],

  updateUserTypeRequest: [
    "payload", "resolve", "reject"
  ],
  updateUserTypeSuccess: [
    "payload", "resolve", "reject"
  ],
  updateUserTypeFailure: ["error"],

  updateRoleRequest: [
    "payload", "resolve", "reject"
  ],
  updateRoleSuccess: [
    "payload", "resolve", "reject"
  ],
  updateRoleFailure: ["error"],
 setCurrentUser: ["payload", "resolve", "reject"],

 fetchRunsRequest: [
   "payload", "resolve", "reject"
 ],
 fetchRunsSuccess: [
   "payload", "resolve", "reject"
 ],
 fetchRunsFailure: ["error"],

 abandonRunRequest: [
   "payload", "resolve", "reject"
 ],
 abandonRunSuccess: [
   "payload", "resolve", "reject"
 ],
 abandonRunFailure: ["error"],

 bumpRunRequest: [
   "payload", "resolve", "reject"
 ],
 bumpRunSuccess: [
   "payload", "resolve", "reject"
 ],
 bumpRunFailure: ["error"],


  defaultHandler: []

})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  users: [],
  runs: [],
  newUser: {},
  currentUser: {},
  classStudent: {},
  licenses:[],
  currentMembershipUser: {},
  requestedUser: {},
  requesting: false,
  error: "",
  message: ""
})

/* ------------- Reducers ------------- */
export const setCurrentUser = (state, action) =>  state.merge({currentUser: action.payload})


export const fetchUsersRequest = (state, action) => state.merge({requesting: true, error: null})

export const fetchUsersSuccess = (state, action) => state.merge({requesting: false, error: null, users: action.payload})

export const fetchUsersFailure = (state, {error}) => state.merge({requesting: false, error})

export const fetchUserRequest = (state, action) => state.merge({requesting: true, error: null})

export const fetchUserSuccess = (state, action) => state.merge({requesting: false, error: null, currentUser: action.payload})

export const fetchUserFailure = (state, {error}) => {
  console.log(error);
  return state.merge({requesting: false, error})
}



export const updateExpirationRequest = (state, action) => state.merge({requesting: true, error: null})

export const updateExpirationSuccess = (state, action) => state.merge({requesting: false, error: null})

export const updateExpirationFailure = (state, {error}) => state.merge({requesting: false, error})



export const updateUserTypeRequest = (state, action) => state.merge({requesting: true, error: null})

export const updateUserTypeSuccess = (state, action) => state.merge({requesting: false, error: null, currentUser: action.payload})

export const updateUserTypeFailure = (state, {error}) => state.merge({requesting: false, error})

export const updateUserEmailRequest = (state, action) => state.merge({requesting: true, error: null})


export const updateUserEmailSuccess = (state, action) => {
  return state.merge({requesting: false, error: null, currentUser: action.payload})

} 
export const updateUserEmailFailure = (state, {error}) => state.merge({requesting: false, error})

export const updateRoleRequest = (state, action) => state.merge({requesting: true, error: null})

export const updateRoleSuccess = (state, action) => state.merge({requesting: false, error: null, currentUser: action.payload})

export const updateRoleFailure = (state, {error}) => state.merge({requesting: false, error})

export const fetchRunsRequest = (state, action) => state.merge({requesting: true, error: null})

export const fetchRunsSuccess = (state, action) => 
state.merge({
  requesting: false, 
  error: null, 
  currentUser: {
    classStudent: {
      studentRuns: action.payload
    }
  }
})

export const fetchRunsFailure = (state, {error}) => state.merge({requesting: false, error})

export const abandonRunRequest = (state, action) => state.merge({requesting: true, error: null})

export const abandonRunSuccess = (state, action) => state.merge({requesting: false, error: null, currentUser: action.payload})

export const abandonRunFailure = (state, {error}) => state.merge({requesting: false, error})

export const bumpRunRequest = (state, action) => state.merge({requesting: true, error: null})

export const bumpRunSuccess = (state, action) => state.merge({requesting: false, error: null, currentUser: action.payload})

export const bumpRunFailure = (state, {error}) => state.merge({requesting: false, error})

export const defaultHandler = (state, action) => state

/* ------------- Hookup Reducers To Types ------------- */

export const HANDLERS = {
  [Types.FETCH_USERS_REQUEST]: fetchUsersRequest,
  [Types.FETCH_USERS_SUCCESS]: fetchUsersSuccess,
  [Types.FETCH_USERS_FAILURE]: fetchUsersFailure,
  [Types.FETCH_USER_REQUEST]: fetchUserRequest,
  [Types.FETCH_USER_SUCCESS]: fetchUserSuccess,
  [Types.FETCH_USER_FAILURE]: fetchUserFailure,

  [Types.UPDATE_EXPIRATION_REQUEST]: updateExpirationRequest,
  [Types.UPDATE_EXPIRATION_SUCCESS]: updateExpirationSuccess,
  [Types.UPDATE_EXPIRATION_FAILURE]: updateExpirationFailure,
  [Types.UPDATE_USER_TYPE_REQUEST]: updateUserTypeRequest,
  [Types.UPDATE_USER_TYPE_SUCCESS]: updateUserTypeSuccess,
  [Types.UPDATE_USER_TYPE_FAILURE]: updateUserTypeFailure,
  [Types.UPDATE_USER_EMAIL_REQUEST]: updateUserEmailRequest,
  [Types.UPDATE_USER_EMAIL_SUCCESS]: updateUserEmailSuccess,
  [Types.UPDATE_USER_EMAIL_FAILURE]: updateUserEmailFailure,
  [Types.UPDATE_ROLE_REQUEST]: updateRoleRequest,
  [Types.UPDATE_ROLE_SUCCESS]: updateRoleSuccess,
  [Types.UPDATE_ROLE_FAILURE]: updateRoleFailure,
  [Types.SET_CURRENT_USER]: setCurrentUser,
  [Types.FETCH_RUNS_REQUEST]: fetchRunsRequest,
  [Types.FETCH_RUNS_SUCCESS]: fetchRunsSuccess,
  [Types.FETCH_RUNS_FAILURE]: fetchRunsFailure,
  [Types.ABANDON_RUN_REQUEST]: abandonRunRequest,
  [Types.ABANDON_RUN_SUCCESS]: abandonRunSuccess,
  [Types.ABANDON_RUN_FAILURE]: abandonRunFailure,
  [Types.BUMP_RUN_REQUEST]: bumpRunRequest,
  [Types.BUMP_RUN_SUCCESS]: bumpRunSuccess,
  [Types.BUMP_RUN_FAILURE]: bumpRunFailure,
  [ReduxSauceTypes.DEFAULT]: defaultHandler
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
