/* eslint-disable prefer-destructuring */

import { call, put } from "redux-saga/effects"
 // import get from "lodash/get"
import Actions from "redux/Actions"
import { saveTokenToLocalStorage, selectToken, removeTokenFromLocalStorage } from "utilities/tokenManager"
import authData from "sagas/__tests__/data/authTestData"
import { push } from 'react-router-redux';
import { parseError } from "./Shared"

const useTestData = false

function * makeLoginRequest (api, action) {
let response
  const { email, password, resolve, reject } = action
  if (useTestData) {
     response = authData[0]

    yield put(Actions.loginSuccess(response.userName))
    yield saveTokenToLocalStorage(response)
    return resolve()
  }

  try {

   response = yield call(api.login, email, password, "password")

} catch (err) {
   console.log("1", err)
}

 if (response.ok) {


   yield saveTokenToLocalStorage(response)
//   yield put(Actions.saveUser
   yield put(Actions.loginSuccess(response.userName))

    return resolve()
  }
  if (response.problem === "CLIENT_ERROR") {
     yield put(Actions.loginFailure(response.data.error_description))
     return reject(response.data.error_description)
  }

   const error = parseError(response)

   console.log(parseError(response))
   yield put(Actions.loginFailure(error))
    return reject("error")


}


export function * makeSignoutRequest (api, action) {
  const { resolve, reject } = action
  const token = yield call(selectToken)
//  const response = yield call(api.signout, token)
  if (token) {
    yield removeTokenFromLocalStorage()
    yield put(Actions.signoutSuccess())
    yield put(push('/login'))
    return resolve()
  }
    return reject()

}




// ADD_SAGA_ACTION

export default {
  // makeVerifyTokenRequest,
  makeLoginRequest,
  makeSignoutRequest
  // makeForgotPasswordRequest,
  // makeSignoutRequest,

  // EXPORT_SAGA_ACTION
}
