import { AuthTypes } from "./AuthRedux"
import { AdminTypes } from "./AdminRedux"
import { ClassroomTypes } from "./ClassroomRedux"
import { UserTypes } from "./UserRedux"
import { MembershipTypes } from "./MembershipRedux"
// ADD_IMPORT

export default {
  ...AuthTypes,
  ...AdminTypes,
  ...ClassroomTypes,
  ...UserTypes,
  ...MembershipTypes
  // ADD_ACTION_TYPE
}
