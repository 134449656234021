/* eslint-disable no-unused-vars */
import { createActions, createReducer } from "reduxsauce"
import Immutable from "seamless-immutable"
import { map, join } from "lodash"

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ["email", "password", "resolve", "reject"],
  loginSuccess: ["userName"],
  loginFailure: ["error"],
  changeEmail: ["email"],
  changePassword: ["password"],
  changePasswordConfirmation: ["passwordConfirmation"],
  saveUser: ["user"],
  verifyTokenRequest: ["token", "resolve", "reject"],
  forgotPasswordRequest: ["email", "resolve", "reject"],
  forgotPasswordSuccess: null,
  forgotPasswordFailure: ["error"],
  clearForm: null,
  signoutRequest: ["resolve", "reject"],
  signoutSuccess: null,
  // add action here
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  requesting: null,
  currentUser: "",
  error: "",
  authenticated: false
})

/* ------------- Reducers ------------- */

export const loginRequest = (state, action) =>
  state.merge({ requesting: true, error: false})

export const loginSuccess = (state, action) => state.merge({ requesting: false, error: null, authenticated: true, currentUser: action.userName})

export const loginFailure = (state, { error }) =>
  state.merge({requesting: false, error})


export const verifyTokenRequest = (state, action) => state


export const changeEmail = (state, { email }) =>
  state.setIn(["form", "email"], email)


export const changePassword = (state, { password }) =>
    state.setIn(["form", "password"], password)

export const forgotPasswordRequest = (state, action) =>
  state.merge({ requesting: true, form: { ...state.form, error: {} } })

export const forgotPasswordSuccess = (state, action) =>
  state.merge({ requesting: false, form: { ...state.form, error: {} } })

export const forgotPasswordFailure = (state, { error }) =>
  state.merge({ requesting: false, form: { ...state.form, error: { message: error } } })

export const changePasswordConfirmation = (state, { passwordConfirmation }) =>
    state.setIn(["form", "passwordConfirmation"], passwordConfirmation)

export const signoutRequest = (state, action) => state

export const signoutSuccess = (state, action) =>
  state.merge({authenticated: false, currentUser: ""})



// add new reducer here

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
  [Types.CHANGE_EMAIL]: changeEmail,
  [Types.CHANGE_PASSWORD]: changePassword,
  [Types.CHANGE_PASSWORD_CONFIRMATION]: changePasswordConfirmation,
  [Types.VERIFY_TOKEN_REQUEST]: verifyTokenRequest,
  [Types.FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,
  [Types.SIGNOUT_REQUEST]: signoutRequest,
  [Types.SIGNOUT_SUCCESS]: signoutSuccess,

  // add reducer hook up here

})
