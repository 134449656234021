import { call, put } from "redux-saga/effects"
import get from "lodash/get"
import { selectToken } from "utilities/tokenManager"
import Actions from "redux/Actions"
import { parseError } from "./Shared"

// change this variable to true to use test data
// const useTestData = false


function * makeFetchClassroomsRequest (api, action) {
    const { resolve, reject } = action
    const token = yield call(selectToken)

   // if (useTestData) {
   //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
   //   return resolve()
   // }

     const response = yield call(api.fetchClassrooms, token)

     if (response.ok) {
       const classrooms = get(response, "data")

       yield put(Actions.fetchClassroomsSuccess(classrooms))
       return resolve()
     }
       const error = parseError(response)
       yield put(Actions.fetchClassroomsFailure(error))
       return reject(error)


  }
  function * makeAddClassroomRequest (api, action) {
      const { payload, resolve, reject } = action
      const token = yield call(selectToken)

     // if (useTestData) {
     //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
     //   return resolve()
     // }

       const response = yield call(api.setupNewClassroom, payload, token)

       if (response.ok) {
         yield put(Actions.addClassroomSuccess())
         yield put(Actions.fetchClassroomsRequest(resolve, reject))
         return resolve()
       }
         const error = parseError(response)
         yield put(Actions.addClassroomFailure(error))
         return reject(error)


    }

    function * makeInactivateClassroomRequest (api, action) {
       const {  payload , resolve, reject } = action
      
      const token = yield call(selectToken)

     // if (useTestData) {
     //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
     //   return resolve()
     // }

       const response = yield call(api.inactivateClassroom, payload, token)

       if (response.ok) {
         yield put(Actions.inactivateClassroomSuccess())
         yield put(Actions.fetchClassroomsRequest(resolve, reject))
         return resolve()
       }
         const error = parseError(response)
         yield put(Actions.inactivateClassroomFailure(error))
         return reject(error)


    }
    function * makeRunningClassroomRequest (api, action) {
      const {  payload , resolve, reject } = action
     
     const token = yield call(selectToken)

    // if (useTestData) {
    //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
    //   return resolve()
    // }

      const response = yield call(api.runningClassroom, payload, token)

      if (response.ok) {
        yield put(Actions.runningClassroomSuccess())
        yield put(Actions.fetchClassroomsRequest(resolve, reject))
        return resolve()
      }
        const error = parseError(response)
        yield put(Actions.runningClassroomFailure(error))
        return reject(error)


   }
   function * makeRegistrationClassroomRequest (api, action) {
    const {  payload , resolve, reject } = action
   
   const token = yield call(selectToken)

  // if (useTestData) {
  //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
  //   return resolve()
  // }

    const response = yield call(api.registrationClassroom, payload, token)

    if (response.ok) {
      yield put(Actions.registrationClassroomSuccess())
      yield put(Actions.fetchClassroomsRequest(resolve, reject))
      return resolve()
    }
      const error = parseError(response)
      yield put(Actions.registrationClassroomFailure(error))
      return reject(error)


 }
  function * makeFetchInstructorsRequest (api, action) {
      const { resolve, reject } = action
      const token = yield call(selectToken)

     // if (useTestData) {
     //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
     //   return resolve()
     // }

       const response = yield call(api.fetchInstructors, token)
       if (response.ok) {
         const instructors = get(response, "data")
         yield put(Actions.fetchInstructorsSuccess(instructors))
         return resolve()
       }
         const error = parseError(response)
         yield put(Actions.fetchInstructorsFailure(error))
         return reject(error)


    }


    function * makeAddSecondaryInstructorRequest (api, action) {
      const { payload, resolve, reject } = action
      const token = yield call(selectToken)

     // if (useTestData) {
     //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
     //   return resolve()
     // }

     const response = yield call(api.addSecondaryInstructor, payload, token)

     if (response.ok) {
       yield put(Actions.addSecondaryInstructorSuccess())
       yield put(Actions.fetchClassroomsRequest(resolve, reject))
       return resolve()
     }
       const error = parseError(response)
       yield put(Actions.addSecondaryInstructorFailure(error))
       return reject(error)

    }
    function * makeRemoveSecondaryInstructorRequest (api, action) {
      const { payload, resolve, reject } = action
      const token = yield call(selectToken)

     // if (useTestData) {
     //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
     //   return resolve()
     // }

     const response = yield call(api.removeSecondaryInstructor, payload, token)

     if (response.ok) {
       yield put(Actions.removeSecondaryInstructorSuccess())
       yield put(Actions.fetchClassroomsRequest(resolve, reject))
       return resolve()
     }
       const error = parseError(response)
       yield put(Actions.removeSecondaryInstructorFailure(error))
       return reject(error)

    }
    
    function * makeChangePrimaryInstructorRequest (api, action) {
      const { payload, resolve, reject } = action
      const token = yield call(selectToken)

     // if (useTestData) {
     //   yield put(Actions.fetchClassroomsSuccess(adminUsersData))
     //   return resolve()
     // }

     const response = yield call(api.changePrimaryInstructor, payload, token)

     if (response.ok) {
       yield put(Actions.changePrimaryInstructorSuccess())
       yield put(Actions.fetchClassroomsRequest(resolve, reject))
       return resolve()
     }
       const error = parseError(response)
       yield put(Actions.changePrimaryInstructorFailure(error))
       return reject(error)

    }


// ADD_SAGA_ACTION

export default {
    makeFetchClassroomsRequest,
    makeFetchInstructorsRequest,
    makeAddClassroomRequest,
    makeAddSecondaryInstructorRequest,
    makeRemoveSecondaryInstructorRequest,
    makeChangePrimaryInstructorRequest,
    makeInactivateClassroomRequest,
    makeRunningClassroomRequest,
    makeRegistrationClassroomRequest
    // EXPORT_SAGA_ACTION
  }
