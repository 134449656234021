import React, { Component } from "react";
import { Button, Alert } from "reactstrap";
import styled from "styled-components";
import Papa from "papaparse";
import { TableHeaderColumn } from "react-bootstrap-table";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import Actions from "redux/Actions";
import StyledBootstrapTable from "../../../../components/styled/BootstrapTable.js";

const UploadHeader = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: center;

	> .btn:first-child {
		position: relative;
		overflow: hidden;
		display: inline-block;
	}

	> .add-users-btn {
		margin-left: auto;
	}

	> * {
		margin: 0 10px 0;
		padding: 0.5rem 0.75rem;
	}

	input {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		opacity: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
`;

class ImportUsers extends Component {
	state = {
		uploadData: [],
		feedback: ""
	};

	handleFileUpload = () => {
		const that = this;
		Papa.parse(this.uploadFileRef.files[0], {
			header: true,
			dynamicTyping: true,
			complete(results) {
				const feedback = {
					text: this.errors
						? this.errors[0].message
						: "File successfully parsed.",
					color: this.errors ? "danger" : "success"
				};
				const importData = []

				let keys = [];
  		let d = {};
  		keys = Object.keys(results.data);

  	for (let i = 0; i < keys.length; i++) {
					 d = results.data[i];
					if (d.first !== null && d.first !== undefined) {
						importData.push(d);
					}
				}
				that.setState({
					uploadData: importData.slice(0),
					feedback
				});
			}
		});
	};

	addToUsersTable = () => {
		// Lori, add this.state.uploadData to the Users here...

			const payload = {
			      studentList: this.state.uploadData.slice(0)
			    };

		 const uploadData = JSON.stringify(payload)

		this.props.bulkAddUsers(uploadData)
	

	};

	render() {
		return (
			<div>
				<UploadHeader>
					<Button outline color="primary">
						<i className="fa fa-upload" /> Upload CSV file

						<input
							type="file"
							accept=".csv"
							onChange={this.handleFileUpload}
							ref={node => {
								this.uploadFileRef = node;
							}}
						/>
		</Button>
					{this.state.feedback ? (
						<Alert color={this.state.feedback.color}>
							{this.state.feedback.text}
						</Alert>
					) : (
						""
					)}
					{this.state.feedback.color === "success" ? (
						<Button
							className="add-users-btn"
							onClick={this.addToUsersTable}
							color="primary"
						>
							Add to Users
						</Button>
					) : (
						""
					)}
				</UploadHeader>


				<StyledBootstrapTable
					data={this.state.uploadData}
				>
					<TableHeaderColumn dataField="first" width="100">
						First Name
					</TableHeaderColumn>
					<TableHeaderColumn dataField="last" width="100">Last Name</TableHeaderColumn>
					<TableHeaderColumn isKey dataField="email" width="150" trStyle={{ whiteSpace: "normal" }} >Email</TableHeaderColumn>
					<TableHeaderColumn dataField="comment" width="100">
						Comment
					</TableHeaderColumn>
					<TableHeaderColumn headerAlign="center" thStyle={{ whiteSpace: "normal" }} dataAlign='center' width="70" dataField="productId" >
						Product Id
					</TableHeaderColumn>
					<TableHeaderColumn headerAlign="center" thStyle={{ whiteSpace: "normal" }} dataAlign='center' width="70" dataField="studentType">Student Type</TableHeaderColumn>

				</StyledBootstrapTable>



			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({

});

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => new Promise((resolve, reject) => dispatch(Actions.fetchUsersRequest(resolve, reject))),
  bulkAddUsers: (payload) => new Promise((resolve, reject) => dispatch(Actions.bulkAddUsersRequest(payload, resolve, reject))),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsers);
