import React, { useReducer, createContext } from "react";

export const AgileContext = createContext();

export const initialState = {
  loading: true,
  agileUsers: [],
  currentUser: [],
  agileClassrooms: []
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOADING": {
      return {
          ...state,
        loading: action.payload.isLoading
      }
    }  
    case "RECEIVE_AGILE_USERS": {
      return {
        ...state,
        loading:false,
        agileUsers: action.payload.users
      };
    }
    case "SET_CURRENT_USER": {
      return {
        ...state,
        loading: false,
        currentUser: action.currentUser
      }
    }
    default:
      return {
        ...state
      };
  }
};

export const AgileContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AgileContext.Provider value={[state, dispatch]}>
      {props.children}
    </AgileContext.Provider>
  );
};
