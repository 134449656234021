/* eslint-disable no-unused-vars */
import {createActions, createReducer, Types as ReduxSauceTypes} from "reduxsauce"
import Immutable from "seamless-immutable"

/* ------------- Types and Action Creators ------------- */
const {Types, Creators} = createActions({

  fetchMembershipUsersRequest: [
    "resolve", "reject"
  ],

  fetchMembershipUsersSuccess: [
    "payload", "resolve", "reject"
  ],

fetchMembershipUsersFailure: ["error"],

fetchMembershipUserRequest: [
  "payload", "resolve", "reject"
],

fetchMembershipUserSuccess: [
  "payload", "resolve", "reject"
],

fetchMembershipUserFailure: ["error"],

checkMbrUserStatusRequest: [
  "payload", "resolve", "reject"
],

checkMbrUserStatusSuccess: [
  "payload", "resolve", "reject"
],

checkMbrUserStatusFailure: ["error"],


addUserRequest: [
  "payload", "resolve", "reject"
],
addUserSuccess: [
  "payload", "resolve", "reject"
],
addUserFailure: ["error"],


adminResetPasswordRequest:["payload", "resolve", "reject"],
adminResetPasswordSuccess: ["payload", "resolve", "reject"],
adminResetPasswordFailure: ["error"],

bulkAddUsersRequest: [
  "payload", "resolve", "reject"
],
bulkAddUsersSuccess: [
  "payload", "resolve", "reject"
],
bulkAddUsersFailure: ["error"],


updateEmailRequest: [
  "payload", "resolve", "reject"
],
updateEmailSuccess: [
  "payload", "resolve", "reject"
],
updateEmailFailure: ["error"],

defaultHandler: []
})


export const MembershipTypes = Types
export default Creators


/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  membershipUsers: [],
  currentMembershipUser: {},
  requesting: false,
  error: "",
  message: ""
})

/* ------------- Reducers ------------- */

export const fetchMembershipUsersRequest = (state, action) => state.merge({requesting: true, error: null})

export const fetchMembershipUsersSuccess = (state, action) => state.merge({requesting: false, error: null, membershipUsers: action.payload})

export const fetchMembershipUsersFailure = (state, {error}) => state.merge({requesting: false, error})


export const fetchMembershipUserRequest = (state, action) => state.merge({requesting: true, error: null})

export const fetchMembershipUserSuccess = (state, action) => state.merge({requesting: false, error: null, currentMembershipUser: action.payload})

export const fetchMembershipUserFailure = (state, {error}) => state.merge({requesting: false, error})



export const checkMbrUserStatusRequest = (state, action) => state.merge({requesting: true, error: null})

export const checkMbrUserStatusSuccess = (state, action) => state.merge({requesting: false, error: null, currentMembershipUser: action.payload})

export const checkMbrUserStatusFailure = (state, {error}) => state.merge({requesting: false, error})


export const adminResetPasswordRequest = (state, action) => state.merge({requesting: true, error: null})

export const adminResetPasswordSuccess = (state, action) => state.merge({requesting: false, error: null})

export const adminResetPasswordFailure = (state, {error}) =>  state.merge({requesting: false, error})

export const addUserRequest = (state, action) => state.merge({requesting: true, error: null})

export const addUserSuccess = (state, action) => state.merge({requesting: false, error: null, currentMembershipUser: action.payload})

export const addUserFailure = (state, {error}) => state.merge({requesting: false, error})


export const updateEmailRequest = (state, action) => state.merge({requesting: true, error: null})

export const updateEmailSuccess = (state, action) => {
  return state.merge({requesting: false, 
    error: null, 
    currentMembershipUser: action.payload})

} 
export const updateEmailFailure = (state, {error}) => state.merge({requesting: false, error})


export const bulkAddUsersRequest = (state, action) => state.merge({requesting: true, error: null})

export const bulkAddUsersSuccess = (state, action) => state.merge({requesting: false, error: null, currentMembershipUser: action.payload})

export const bulkAddUsersFailure = (state, {error}) => state.merge({requesting: false, error})


export const defaultHandler = (state, action) => state

/* ------------- Hookup Reducers To Types ------------- */

export const HANDLERS = {
  [Types.FETCH_MEMBERSHIP_USERS_REQUEST]: fetchMembershipUsersRequest,
  [Types.FETCH_MEMBERSHIP_USERS_SUCCESS]: fetchMembershipUsersSuccess,
  [Types.FETCH_MEMBERSHIP_USERS_FAILURE]: fetchMembershipUsersFailure,
  [Types.FETCH_MEMBERSHIP_USER_REQUEST]: fetchMembershipUserRequest,
  [Types.FETCH_MEMBERSHIP_USER_SUCCESS]: fetchMembershipUserSuccess,
  [Types.FETCH_MEMBERSHIP_USER_FAILURE]: fetchMembershipUserFailure,
  [Types.CHECK_MBR_USER_STATUS_REQUEST]: checkMbrUserStatusRequest,
  [Types.CHECK_MBR_USER_STATUS_SUCCESS]: checkMbrUserStatusSuccess,
  [Types.CHECK_MBR_USER_STATUS_FAILURE]: checkMbrUserStatusFailure,
  [Types.ADD_USER_REQUEST]: addUserRequest,
  [Types.ADD_USER_SUCCESS]: addUserSuccess,
  [Types.ADD_USER_FAILURE]: addUserFailure,
  [Types.ADMIN_RESET_PASSWORD_REQUEST]: adminResetPasswordRequest,
  [Types.ADMIN_RESET_PASSWORD_SUCCESS]: adminResetPasswordSuccess,
  [Types.ADMIN_RESET_PASSWORD_FAILURE]: adminResetPasswordFailure,
  [Types.UPDATE_EMAIL_REQUEST]: updateEmailRequest,
  [Types.UPDATE_EMAIL_SUCCESS]: updateEmailSuccess,
  [Types.UPDATE_EMAIL_FAILURE]: updateEmailFailure,
  [Types.BULK_ADD_USERS_REQUEST]: bulkAddUsersRequest,
  [Types.BULK_ADD_USERS_SUCCESS]: bulkAddUsersSuccess,
  [Types.BULK_ADD_USERS_FAILURE]: bulkAddUsersFailure,
  [ReduxSauceTypes.DEFAULT]: defaultHandler
}

export const reducer = createReducer(INITIAL_STATE, HANDLERS)
